import React, { ElementType } from "react";
import Link from "next/link";

import * as S from "./styled";

interface IProps {
  variant?: "minimal" | "default" | "primary" | "secondary";
  onClick?: () => void;
  isLink?: boolean;
  path?: string;
  label?: string;
  maxWidth?: number | string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  disabled?: boolean;
  type?: "button" | "reset" | "submit" | undefined;
}

type ElementTypeProps =
  | {
      as: ElementType;
      href: string | undefined;
    }
  | {
      onClick: (() => void) | undefined;
      type: "button" | "reset" | "submit" | undefined;
    };

const Button = ({
  variant = "default",
  onClick,
  isLink,
  path,
  label,
  maxWidth = 200,
  style,
  children,
  disabled,
  type = "button",
  ...propsElement
}: IProps) => {
  const props: ElementTypeProps = isLink ? { as: Link, href: path } : { type: "button", onClick };
  return (
    <S.Button
      {...props}
      type={type}
      variant={variant}
      disabled={disabled || false}
      style={{
        ...style,
        maxWidth
      }}
      {...propsElement}>
      <span>{label || children}</span>
    </S.Button>
  );
};

export default Button;
