import { IconProps } from "@/icons/types";

const Belarus = ({ size = "16px", ...props }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_8_415)">
      <path
        d="M2.66666 13.7778H14.2222C14.6937 13.7778 15.1459 13.5905 15.4793 13.2571C15.8127 12.9237 16 12.4715 16 12V10.2222H2.66666V13.7778Z"
        fill="#4AA657"
      />
      <path
        d="M14.2222 2.22223H2.66666V10.2222H16V4.00001C16 3.52851 15.8127 3.07633 15.4793 2.74293C15.1459 2.40953 14.6937 2.22223 14.2222 2.22223Z"
        fill="#C8313E"
      />
      <path
        d="M1.77778 2.22223C1.30628 2.22223 0.854097 2.40953 0.520699 2.74293C0.187301 3.07633 0 3.52851 0 4.00001L0 12C0 12.4715 0.187301 12.9237 0.520699 13.2571C0.854097 13.5905 1.30628 13.7778 1.77778 13.7778H2.66667V2.22223H1.77778Z"
        fill="white"
      />
      <path
        d="M0.340456 12.9725L0.618233 12.4796L0.903122 12.9867L0.662233 13.3738C0.738017 13.4356 0.818898 13.4909 0.904011 13.5391L1.25601 12.9005L0.6249 11.8889L0.128456 12.656C0.209789 12.8605 0.3289 13.0436 0.475567 13.2027L0.340456 12.9725ZM2.65823 12.932L1.9929 11.9445L1.32534 12.9093L1.8969 13.7778H2.18446L2.65823 12.932ZM2.01246 12.4791L2.29734 12.9862L2.01246 13.4445L1.73468 12.9725L2.01246 12.4791ZM2.66668 8.14801V7.75067L2.52757 7.94445L2.66668 8.14801ZM1.25557 3.12267L0.858233 2.48534C0.510295 2.69683 0.24583 3.02172 0.109344 3.40534L0.680456 4.16667L1.25557 3.12267ZM0.340456 3.19467L0.618233 2.70178L0.903122 3.2089L0.618233 3.66667L0.340456 3.19467ZM2.05201 4.23601L2.65823 3.15378L2.03068 2.22223H1.95468L1.32579 3.13156L2.05201 4.23601ZM2.2969 3.20845L2.01246 3.66667L1.73468 3.19467L2.01246 2.70178L2.2969 3.20845Z"
        fill="#C8313E"
      />
      <path
        d="M2.20133 6.66666L2.59022 6.02088L2.66667 6.05466V5.77777L2.04178 4.80533L1.29156 5.8471L0.624889 4.77777L0 5.74355V6.03244L0.381778 6.66666L0.652444 6.27066L0.458222 5.99999L0.604 5.80533L1.02756 6.35377L0.402667 7.38133L0 6.8271V7.51733L0.0257778 7.4831L0.449333 8.03155L0 8.7711V9.29422L0.402667 8.73955L1.02756 9.7671L0.676444 10.2222L0.604 10.316L0.533778 10.2222L0.458222 10.1213L0.652889 9.85066L0.382222 9.45466L0 10.0893V10.3449L0.639111 11.3195L1.26133 10.2222L1.28489 10.1804L1.31067 10.2222L2.028 11.3818L2.66667 10.2498V10.076L2.61111 10.1009L2.22222 9.45466L1.95111 9.85066L2.14578 10.1213L2.07022 10.2222L2 10.316L1.92756 10.2222L1.57644 9.76755L2.20133 8.73999L2.66667 9.38044V8.87288L2.15244 8.02755L2.576 7.4791L2.66667 7.59999V6.71244L2.18044 7.38222L1.55556 6.35422L1.97911 5.80577L2.12489 5.99999L1.93022 6.27066L2.20133 6.66666ZM1.90444 7.56933V8.42622L1.28444 9.40533L0.700444 8.42222V7.57422L1.28444 6.59066L1.90444 7.56933Z"
        fill="#C8313E"
      />
      <path
        d="M0.902677 7.47692L1.16846 7.96181L0.902677 8.4467L1.09734 8.55336L1.29512 8.19292L1.4929 8.55336L1.68757 8.4467L1.42179 7.96181L1.68757 7.47692L1.4929 7.37025L1.29512 7.7307L1.09734 7.37025L0.902677 7.47692ZM0.0115662 11.6391L0.289344 12.1111L0.574233 11.6529L0.289344 11.1458L0.0115662 11.6391ZM0.685344 11.6391L0.963122 12.1111L1.24757 11.6529L0.963122 11.1458L0.685344 11.6391ZM1.3449 11.6391L1.62268 12.1111L1.90757 11.6529L1.62268 11.1458L1.3449 11.6391ZM2.05246 11.6391L2.33023 12.1111L2.61468 11.6529L2.33023 11.1458L2.05246 11.6391ZM0.0115662 4.50003L0.289344 4.97247L0.574233 4.51381L0.289344 4.00714L0.0115662 4.50003ZM0.685344 4.50003L0.963122 4.97247L1.24757 4.51381L0.963122 4.00714L0.685344 4.50003ZM1.3449 4.50003L1.62268 4.97247L1.90757 4.51381L1.62268 4.00714L1.3449 4.50003ZM2.05246 4.50003L2.33023 4.97247L2.61468 4.51381L2.33023 4.00714L2.05246 4.50003Z"
        fill="#C8313E"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_415">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Belarus;
