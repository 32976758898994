import { IconProps } from "@/icons/types";

const OdnoklassnikiIcon = ({ size = "40px", fill = "#fff" }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0C31.0449 0 40 8.95508 40 20C40 31.0449 31.0449 40 20 40C8.95508 40 0 31.0449 0 20C0 8.95508 8.95508 0 20 0ZM22.2363 25.2507C24.6615 24.7559 26.1165 23.6068 26.1947 23.5449C26.9043 22.9753 27.0182 21.9401 26.4486 21.2272C25.8789 20.5176 24.8405 20.4036 24.1309 20.9733C24.1178 20.9863 22.5651 22.1745 20.013 22.1777C17.4609 22.1745 15.8789 20.9863 15.8626 20.9733C15.153 20.4036 14.1146 20.5176 13.5449 21.2272C12.9753 21.9368 13.0892 22.9753 13.7988 23.5449C13.877 23.6068 15.3906 24.7852 17.8841 25.2702L14.4141 28.8997C13.7826 29.554 13.8021 30.599 14.4564 31.2305C14.7754 31.5397 15.1888 31.6927 15.599 31.6927C16.0319 31.6927 16.4616 31.5234 16.7839 31.1882L20.013 27.7474L23.5677 31.2109C24.2122 31.8555 25.2539 31.8522 25.8984 31.2077C26.543 30.5632 26.5397 29.5215 25.8952 28.877L22.2363 25.2507ZM20.0977 8.30729C16.7513 8.30729 14.0365 11.0189 14.0365 14.3685C14.0365 17.7148 16.748 20.4297 20.0977 20.4297C23.444 20.4297 26.1589 17.7148 26.1589 14.3685C26.1589 11.0221 23.4473 8.30729 20.0977 8.30729ZM20.0977 16.8717C18.7142 16.8717 17.5944 15.7487 17.5944 14.3652C17.5944 12.9818 18.7174 11.862 20.0977 11.862C21.4811 11.862 22.6042 12.9818 22.6042 14.3652C22.6042 15.752 21.4811 16.8717 20.0977 16.8717Z"
      fill={fill}
    />
  </svg>
);

export default OdnoklassnikiIcon;
