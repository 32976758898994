import { IconProps } from "@/icons/types";

const InfoIcon = ({ size = "24px", fill = "#555555", ...props }: IconProps) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.78625 0H20.5693C21.3584 0 22.0002 0.71865 22 1.60179V8.63863C22 9.52092 21.358 10.2387 20.5688 10.2387H19.3093V13.1142C19.3093 13.195 19.2889 13.2742 19.2504 13.3421C19.2119 13.41 19.157 13.4638 19.0922 13.497C19.0275 13.5302 18.9557 13.5414 18.8856 13.5292C18.8154 13.517 18.7498 13.482 18.6967 13.4283L15.5336 10.2387H14.3908V14.105C14.3908 14.9876 13.7487 15.7054 12.9598 15.7054H6.46663L3.30356 18.895C3.25039 18.9484 3.18483 18.9832 3.11472 18.9953C3.04462 19.0074 2.97294 18.9961 2.90829 18.963C2.84364 18.9298 2.78876 18.8761 2.75022 18.8084C2.71168 18.7406 2.69111 18.6616 2.69099 18.5808V15.7054H1.43122C0.642045 15.7054 0 14.9873 0 14.105V7.06817C0 6.18503 0.642045 5.46638 1.43122 5.46638L7.35503 5.45855V1.60179C7.35503 0.71865 7.99708 0 8.78625 0ZM13.6985 3.93223C13.971 3.6203 13.9391 3.1465 13.6271 2.87398C13.3152 2.60146 12.8414 2.63341 12.5689 2.94535L9.10982 6.90475C8.6578 7.42214 9.02525 8.23109 9.71229 8.23109H10.7016L8.30732 10.5416C8.00926 10.8292 8.00081 11.304 8.28844 11.6021C8.57607 11.9001 9.05087 11.9086 9.34893 11.621L12.9906 8.10675C13.5084 7.60705 13.1547 6.73109 12.435 6.73109H11.2533L13.6985 3.93223Z"
      fill={fill}
    />
  </svg>
);

export default InfoIcon;
