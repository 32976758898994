import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface SelectedTrack {
  tenant_name: string;
  date: string;
  id: number;
  trackDate: string;
  startDate: string;
  arrivalDate: string;
  tenant_id: number;
  bus_name: string;
  bus_number: string;
  discount: number;
  allowance: string;
  end_point_arrival_time: string;
  end_point_departure_time: string;
  end_point_place: string;
  expiredDate: string;
  start_point_arrival_time: string;
  start_point_departure_time: string;
  start_point_place: string;
  fromCityName: string;
  toCityName: string;
  cityFrom: number;
  cityTo: number;
  total: number;
  totalRUB: number;
  track_options: TrackOption[];
  tickets: Tickets;
  isThirdpartyCarrier: boolean;
  isRosbilet: boolean;
  rosbiletRouteID: number;
  bus_size: number;
  free_places: string[];
  reserved_places: number[];
  carrier: string;
}

export interface TrackOption {
  id: number;
  name: string;
}

export interface Tickets {
  adult: number;
  child: number;
}

interface InitialState {
  tracks: SelectedTrack[];
  backTrip: string | null;
}

const initialState: InitialState = {
  tracks: [],
  backTrip: null
};

const selectedTracksSlice = createSlice({
  name: "selectedTracks",
  initialState,
  reducers: {
    selectedTrackSave: (state, action: PayloadAction<any[]>) => {
      // Replace "any" with the actual track type or interface
      localStorage.setItem("selectedTracks", JSON.stringify(action.payload));
      state.tracks = action.payload;
    }
  }
});

export const { selectedTrackSave } = selectedTracksSlice.actions;
export default selectedTracksSlice.reducer;
