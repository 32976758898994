import { IconProps } from "@/icons/types";

const PhoneIcon = ({ size = "24px", fill = "#fff" }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.37 10.79C8.81 13.62 11.13 15.93 13.96 17.38L16.16 15.18C16.43 14.91 16.83 14.82 17.18 14.94C18.3 15.31 19.51 15.51 20.75 15.51C21.3 15.51 21.75 15.96 21.75 16.51V20C21.75 20.55 21.3 21 20.75 21C11.36 21 3.75 13.39 3.75 4C3.75 3.45 4.2 3 4.75 3H8.25C8.8 3 9.25 3.45 9.25 4C9.25 5.25 9.45 6.45 9.82 7.57C9.93 7.92 9.85 8.31 9.57 8.59L7.37 10.79Z"
      fill={fill}
      fillOpacity="0.9"
    />
  </svg>
);

export default PhoneIcon;
