import styled from "@emotion/styled";

import { colors } from "@/styles/variables";

export const Footer = styled.footer`
  border-radius: 0 0 4px 4px;
  border-top: 1px solid ${colors.neutral150};

  background: #f6f6f9;
  padding: 16px 20px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;
