import React from "react";
import { MenuItem, Menu, MenuButton, MenuList } from "@chakra-ui/menu";
import { forwardRef } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import { BYN, KZT, RUB, UAH } from "@/utils/constants";
import Icons from "@/icons";

import * as S from "./styled";
interface IProps {
  value: string;
  onChange: (newValue: string) => void;
}

const ButtonText = forwardRef((props, ref) => (
  <S.ButtonText ref={ref} {...props}>
    {props.children}
    {props["aria-expanded"] ? <ChevronUpIcon /> : <ChevronDownIcon />}
  </S.ButtonText>
));

const CurrencySelect = ({ value, onChange }: IProps) => {
  const currencyAbb = [
    { key: BYN, icon: Icons.Flags.Belarus },
    { key: RUB, icon: Icons.Flags.Russia },
    { key: KZT, icon: Icons.Flags.Kazakhstan },
    { key: UAH, icon: Icons.Flags.Ukraine }
  ];
  const currencyOptions = currencyAbb.map((item) => ({ value: item.key, label: item.key, icon: item.icon }));
  if (!currencyOptions.length) return null;
  const currentValue = currencyOptions?.find((x) => x.value === value) || {
    label: "BYN",
    value: "BYN",
    icon: Icons.Flags.Belarus
  };
  const CurrentIcon = currentValue.icon || null;

  return (
    <Menu>
      <MenuButton as={ButtonText} w="80px">
        <S.Wrapper>
          <CurrentIcon />
          {currentValue?.label}
        </S.Wrapper>
      </MenuButton>
      <MenuList p={0} minW="0" w="80px" color="#000">
        {currencyOptions?.map(({ label, value, icon: Icon }, index) => (
          <MenuItem
            key={index}
            onClick={() => onChange(value)}
            style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Icon />
            {label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default CurrencySelect;
