import { newColors as colors } from "@/styles/variables";

const radioTheme = {
  sizes: { lg: { label: { fontSize: "16px" } } },
  baseStyle: () => ({
    control: {
      borderColor: colors.gray,
      _checked: {
        borderColor: colors.mainBlue,
        bg: colors.white,
        _before: {
          bg: colors.mainBlue,
          w: "10px",
          h: "10px"
        },
        _hover: {
          bg: colors.white,
          borderColor: colors.mainBlue
        }
      }
    }
  })
};

export default radioTheme;
