import req from "superagent";
import { encode } from "js-base64";

import { Geolocation, GeolocationErrorMessage } from "@/types/geo";
import { BY, GET, POST, RU } from "@/utils/constants";

import config from "./config";

const apiBasePath = config.apiBasePath;
let ksToken = null;

const routeMap = {
  getCities: "getCities",
  getSearchBuses: "getSearchBuses",
  searchRosbilet: "searchRosbilet",
  getSearchBusesFuture: "getSearchBusesFuture",
  getNationality: "getNationality",
  getDocuments: "getDocuments",
  getNews: "getNews",
  getSeoNews: "getSeoNews",
  getTerms: "getTerms",
  getTermRelations: "getTermRelations",
  getReservedPlaces: "getReservedPlaces",
  reserveTickets: "reserveTickets",
  reserveThirdPartyTickets: "reserveThirdPartyTickets",
  getPaymentData: "getPaymentData",
  getIntercarsPaymentData: "getIntercarsPaymentData",
  pay: "pay",
  sendSms: "sendSms",
  validateSmsCode: "validateSmsCode",
  checkAuth: "checkAuth",
  getUserInfo: "getUserInfo",
  changeUserInfo: "changeUserInfo",
  getUserFeatureTrips: "getUserFeatureTrips",
  getUserLastTrips: "getUserLastTrips",
  printTickets: "printTickets",
  printIntercarsTickets: "printIntercarsTickets",
  printRosbiletTickets: "printRosbiletTickets",
  supportEmail: "supportEmail",
  contactEmail: "contactEmail",
  partnerEmail: "partnerEmail",
  getSeoData: "getSeoData",
  getSeoCityData: "getSeoCityData",
  getSeoCities: "getSeoCities",
  getSoprovojdeniePage: "getSoprovojdeniePage",
  getTimetableCity: "getTimetableCity",
  returnPaymentTickets: "returnPaymentTickets",
  returnPaymentIntercarsTickets: "returnPaymentIntercarsTickets",
  returnPaymentRosbiletTickets: "returnPaymentRosbiletTickets",
  paymentResult: "service/payment"
};

const KS_SESSION_TOKEN = "ksSessionToken";

export const setTokens = (sessionToken) => {
  if (sessionToken) {
    localStorage.setItem(KS_SESSION_TOKEN, sessionToken);
    ksToken = sessionToken;
  }
};

export const sendRequest = (routeName, method = "get", { data } = {}) => {
  const rqst = req[method](routeName in routeMap ? apiBasePath + routeMap[routeName] : routeName)
    .set("Content-type", "application/json")
    .set("Accept", "application/json");
  if (data) {
    if (method.toLowerCase() === "get") {
      rqst.query(data);
    }
    if (method.toLowerCase() === "post" || method.toLowerCase() === "put") {
      rqst.send(data);
    }
  }

  return new Promise((resolve, reject) => {
    rqst.end((err, response) => {
      if (err) {
        reject(response.body);
      } else {
        resolve(response.body);
      }
    });
  });
};

const getKSToken = (isAnonim) =>
  new Promise((resolve, reject) => {
    if (isAnonim) {
      return resolve();
    }

    if (ksToken) {
      resolve(ksToken);
    } else if (localStorage.getItem(KS_SESSION_TOKEN)) {
      ksToken = localStorage.getItem(KS_SESSION_TOKEN);
      resolve(ksToken);
    }

    return reject();
  });

export const sendApiRequest = (
  routeName,
  method = "get",
  { data = {}, params, limit = null, offset = 0 } = {},
  isAnonim = false
) => {
  const dataValue = data;

  return getKSToken(isAnonim).then(() => {
    if (!isAnonim) {
      dataValue.ks = ksToken;
    }

    dataValue.api_key = config.apiKey;

    return sendRequest(routeName, method, { data, params });
  });
};

export const sendCurrencyRequest = () => {
  const request = req(GET, config.apiNbrb);

  return new Promise((resolve, reject) => {
    request.end((err, response) => {
      if (err) {
        reject(response.body);
      } else {
        resolve(response.body);
      }
    });
  });
};

interface GeoTypesResponse {
  ip: string;
  ipNo: string;
  countryCode: string;
  country: string;
}
export const getGeo = async () => {
  const response = await fetch(`${config.apiBasePath}api/locationByIP`);

  if (response.ok) {
    const geoResponse: GeoTypesResponse | GeolocationErrorMessage = await response.json();
    return {
      code: geoResponse?.countryCode?.toLowerCase() ?? BY,
      country: geoResponse?.country ?? "Не определено"
    };
  }

  return {
    code: BY,
    country: "Не определено"
  };
};

export const serverRequestYooKassa = async ({ value, currency, payment }, IdempotenceKey) => {
  const url = `https://${config.yooKassaApi}`;
  const base64Credentials = encode(`${config.yooKassaApiID}:${config.yooKassaApiKey}`);
  const body = {
    amount: {
      value,
      currency
    },
    confirmation: {
      type: "embedded"
    },
    capture: true,
    metadata: payment
  };

  const request = req(POST, url)
    .set("Content-type", "application/json")
    .set("Authorization", `Basic ${base64Credentials}`)
    .set("Idempotence-Key", IdempotenceKey)
    .send(body);
  return new Promise((resolve, reject) => {
    request.end((err, response) => {
      if (err) {
        reject(response.body);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const serverRequestYooKassaSDK = async ({ token, type, value, currency, payment }, IdempotenceKey) => {
  const url = `https://${config.yooKassaApi}`;
  const base64Credentials = encode(`${config.yooKassaApiID}:${config.yooKassaApiKey}`);

  const body = {
    amount: {
      value,
      currency
    },
    confirmation: {
      type: "redirect",
      return_url: "https://probilets.com"
    },
    capture: true,
    metadata: payment,
    payment_token: token
  };

  const request = req(POST, url)
    .set("Content-type", "application/json")
    .set("Authorization", `Basic ${base64Credentials}`)
    .set("Idempotence-Key", IdempotenceKey)
    .send(body);
  return new Promise((resolve, reject) => {
    request.end((err, response) => {
      if (err) {
        reject(response.body);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const serverRequestNotifyPayment = async (data) => {
  const url = `${config.apiBasePathLocal}${routeMap.paymentResult}`;
  const request = req(POST, url).send(data).set("Content-type", "application/json");

  return new Promise((resolve, reject) => {
    request.end((err, response) => {
      if (err) {
        reject(response.body);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const httpCmsRequest = async (param, query = "", formatter?: Function) => {
  try {
    const isObject = typeof param === "object";
    const page = isObject ? param.path : param;
    const url = `${config.strapiCmsHost}/api/${page}${query}`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${config.strapiApiKey}`,
        "Content-type": "application/json"
      }
    };
    const response = await fetch(url, params);
    const { data } = await response.json();

    return formatter ? formatter(data, param, isObject) : data;
  } catch (e) {
    console.log(e);
    return null;
  }
};
