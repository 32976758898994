// @ts-nocheck
import { sendApiRequest } from "@/core/Api";
export const sendSms = async (phone) => {
  const sms = await sendApiRequest(
    "sendSms",
    "post",
    {
      data: {
        phone
      }
    },
    true
  );

  return sms.status === 1;
};

export const validateSmsCode = async (phone, code) => {
  const validCode = await sendApiRequest(
    "validateSmsCode",
    "post",
    {
      data: {
        phone,
        code
      }
    },
    true
  );

  return { valid: validCode.status === 1, token: validCode.token };
};

export const isValidToken = async () => {
  const res = await sendApiRequest(
    "checkAuth",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken")
      }
    },
    true
  );

  return res.status === 1;
};

interface UserData {
  clientCard: string;
  countOfRides: number;
  discount: number | string;
  email: string;
  message: string;
  phone: string;
}
type UserRequest = { data: UserData };
export const getUserInfo = async (): Promise<UserRequest> => {
  const res = await sendApiRequest(
    "getUserInfo",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken")
      }
    },
    true
  );

  return res;
};

export const changeUserInfo = async (email) => {
  const res = await sendApiRequest(
    "changeUserInfo",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken"),
        email
      }
    },
    true
  );

  return res;
};

export const getUserFeatureTrips = async (email) => {
  const res = await sendApiRequest(
    "getUserFeatureTrips",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken"),
        email
      }
    },
    true
  );

  return res;
};

export const getUserLastTrips = async (email) => {
  const res = await sendApiRequest(
    "getUserLastTrips",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken"),
        email
      }
    },
    true
  );

  return res;
};

export const printTickets = async (paymantInvoiceId) => {
  const res = await sendApiRequest(
    "printTickets",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken"),
        paymantInvoiceId
      }
    },
    true
  );

  return res;
};

export const printIntercarsTickets = async (paymantInvoiceId) => {
  return await sendApiRequest(
    "printIntercarsTickets",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken"),
        paymantInvoiceId: `order-${paymantInvoiceId}`
      }
    },
    true
  );
};

export const printRosbiletTickets = async (paymantInvoiceId) => {
  return await sendApiRequest(
    "printRosbiletTickets",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken"),
        paymantInvoiceId: `order-${paymantInvoiceId}`
      }
    },
    true
  );
};

export const returnPaymentTickets = async (paymantInvoiceId) => {
  const res = await sendApiRequest(
    "returnPaymentTickets",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken"),
        paymantInvoiceId
      }
    },
    true
  );

  return res.status === 1;
};

export const returnPaymentIntercarsTickets = async (paymantInvoiceId) => {
  const res = await sendApiRequest(
    "returnPaymentIntercarsTickets",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken"),
        paymantInvoiceId: `order-${paymantInvoiceId}`
      }
    },
    true
  );

  return res.status === 1;
};

export const returnPaymentRosbiletTickets = async (paymantInvoiceId) => {
  const res = await sendApiRequest(
    "returnPaymentRosbiletTickets",
    "post",
    {
      data: {
        token: localStorage.getItem("userToken"),
        paymantInvoiceId: `order-${paymantInvoiceId}`
      }
    },
    true
  );

  return res.status === 1;
};
