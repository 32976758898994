import { newColors as colors } from "@/styles/variables";

const inputTheme = {
  variants: {
    outline: (props: any) => ({
      ...props,
      field: {
        bg: "#ffffff",
        border: "1px solid",
        borderColor: colors.border,
        _focusVisible: {
          borderColor: `${colors.mainBlue} !important`,
          boxShadow: `0 0 0 1px ${colors.mainBlue} !important`
        }
      }
    }),
    base: {
      bg: "#fff"
    }
  },
  baseStyle: {
    field: {
      outline: "none"
    }
  },
  defaultProps: {
    colorScheme: "green",
    size: "lg",
    variant: "sm"
  }
};

export default inputTheme;
