import React, { memo, useState } from "react";
import { Formik, Form, type FormikHelpers } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";

import Modal from "@/components/Modal";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { selectUserModalState } from "@/store/selectors/user";
import Button from "@/components/Buttons";
import { getUserInfo, sendSms, validateSmsCode } from "@/utils/requests/user";
import { setModalVisible } from "@/store/slices/user";
import { InputField } from "@/components/FormComponents";

import * as S from "./styled";
interface InitialValueFormik {
  phone: string;
  code: string;
}
const validationSchema = Yup.object().shape({
  phone: Yup.string().phone(["RU", "BY", "KZ", "UA"], "Заполните номер телефона").required()
});
const AuthModal = () => {
  const authModal = useAppSelector(selectUserModalState);
  const dispatch = useAppDispatch();
  const [isCodeVisible, setCodeVisible] = useState(false);

  const submit = async (values: InitialValueFormik, formikBag: FormikHelpers<InitialValueFormik>) => {
    if (!isCodeVisible) {
      try {
        const phoneNumber = values.phone.replace(/[\(\)\s-]/g, "");

        await sendSms(phoneNumber);

        setCodeVisible(true);
      } catch (e: any) {
        formikBag.setFieldError("phone", e.message);
      }
    } else {
      if (values.code.length !== 5) return;
      const phoneNumber = values.phone.replace(/[\(\)\s-]/g, "");
      const res = await validateSmsCode(phoneNumber, values.code);

      if (res.valid) {
        localStorage.setItem("userToken", res.token);
        handleCloseModal();
        await getUserInfo();
      } else {
        formikBag.setFieldError("code", "Указан неверный код!");
      }
    }
  };

  const handleCloseModal = () => {
    dispatch(setModalVisible());
    setCodeVisible(false);
  };

  return (
    <Modal onClose={handleCloseModal} opened={authModal} title="Вход в личный кабинет" footerVisible={false}>
      <Formik
        initialValues={{ phone: "", code: "" }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={submit}>
        <Form>
          <S.ContentWrapper>
            <S.DescriptionText>
              Регистрация на нашей платформе обеспечивает удобство и быстроту при покупке билетов на мероприятия. Просто
              войдите в свой аккаунт и приобретайте билеты всего в несколько кликов.
            </S.DescriptionText>
            {!isCodeVisible && (
              <S.LabelWrapper>
                <InputField name="phone" label="Номер телефона" />
              </S.LabelWrapper>
            )}
            {isCodeVisible && (
              <S.LabelWrapper>
                <InputField name="code" label="Проверочный код" />
              </S.LabelWrapper>
            )}

            <S.DescriptionText>
              * Ваш номер телефона должен присутствовать в нашей системе. Для этого необходимо хотя бы раз осуществить
              бронирование или покупку билета. При отсутствии Вашего номера в системе КОД отправлен НЕ БУДЕТ. Будьте
              внимательны.
            </S.DescriptionText>

            <S.ButtonWrapper>
              <Button type="submit" label={isCodeVisible ? "Войти" : "Выслать код"} />
            </S.ButtonWrapper>
          </S.ContentWrapper>
        </Form>
      </Formik>
    </Modal>
  );
};

export default memo(AuthModal);
