import Link from "next/link";
import moment from "moment";
import ym from "@appigram/react-yandex-metrika";
import Script from "next/script";
import React from "react";

import { IS_DEVELOP } from "@/utils";
import Icons from "@/icons";
import Button from "@/components/Buttons";
import { setCallbackModalVisible } from "@/store/slices/globalData";
import { useAppDispatch } from "@/store/store";

import * as S from "./styled";

const footerLinks = [
  {
    section: "Пассажирам",
    links: [
      {
        name: "Автобусы онлайн",
        href: "/online"
      },
      {
        name: "Маршруты",
        href: "/routes"
      },
      {
        name: "Расписание",
        href: "/raspisanie"
      },
      {
        name: "Поиск туров",
        href: "https://relax.probilets.com/",
        target: "_blank",
        rel: "nofollow"
      },
      {
        name: "Отели",
        href: "https://hotels.probilets.com/",
        target: "_blank",
        rel: "nofollow"
      },
      {
        name: "Санатории",
        href: "https://relax.probilets.com/sanatoriums",
        target: "_blank",
        rel: "nofollow"
      },
      {
        name: "Проживание",
        href: "https://relax.probilets.com/placements",
        target: "_blank",
        rel: "nofollow"
      },
      {
        name: "Экскурсии",
        href: "https://relax.probilets.com/excursions",
        target: "_blank",
        rel: "nofollow"
      },
      {
        name: "Оплата и возврат",
        href: "/payment-and-refund"
      },

      {
        name: "Спецпредложения",
        href: "/news/special-offers"
      }
    ]
  },
  {
    section: "Компания",
    links: [
      {
        name: "О Нас",
        href: "/about"
      },
      {
        name: "Контакты",
        href: "/contacts"
      },
      {
        name: "Обратная связь",
        href: "/support"
      },
      {
        name: "Реквизиты",
        href: "/requisites"
      },
      {
        name: "Новости",
        href: "/news/newss"
      },
      // {
      //   name: "Отзывы",
      //   href: "/reports"
      // },
      {
        name: "FAQ",
        href: "/faq"
      },
      {
        name: "Договор оферты",
        href: "/contract-offer"
      },
      {
        name: "Политика конфиденциальности",
        href: "/privacy-policy"
      }
    ]
  },
  {
    section: "Партнёрам",
    links: [
      {
        name: "Партнёрская программа",
        href: "/affiliate-program"
      }
    ]
  }
];

const Footer = () => {
  const dispatch = useAppDispatch();
  const openCallbackModal = () => {
    dispatch(setCallbackModalVisible(true));
  };
  return (
    <S.Footer>
      <S.TopFooter>
        <S.FooterContentWrapper>
          <S.LinksContainer>
            {footerLinks.map((group, grIndex) => (
              <S.LinkGroup key={grIndex}>
                <S.GroupTitle>{group.section}</S.GroupTitle>
                <S.GroupLinks>
                  {group.links.map((link, index) => (
                    <S.ListItem key={index}>
                      <Link href={link.href} target={link.target || "_self"} rel={link.rel}>
                        {link.name}
                      </Link>
                    </S.ListItem>
                  ))}
                </S.GroupLinks>
              </S.LinkGroup>
            ))}
          </S.LinksContainer>
          <S.AnotherContent>
            <S.ConnectionWrapper>
              <S.GroupTitle>Связаться с нами</S.GroupTitle>
              <S.ConnectionLinks>
                <S.PhoneWrapper>
                  <S.PhoneIcon>
                    <Icons.Phone />
                  </S.PhoneIcon>
                  <S.PhoneNumbers>
                    <S.Text>
                      <S.ConnectionLink href="tel:+375336748748" target="_blank" rel="noreferrer">
                        +375 (33) 6-748-748
                      </S.ConnectionLink>
                      <S.ConnectionLink href="viber://add?number=375336748748">
                        <Icons.Viber />
                      </S.ConnectionLink>
                    </S.Text>
                    <S.Text>
                      <S.ConnectionLink href="tel:+375296748748" target="_blank" rel="noreferrer">
                        +375 (29) 6-748-748
                      </S.ConnectionLink>
                      <S.ConnectionLink href="https://api.whatsapp.com/send?phone=375296748748">
                        <Icons.Whatsapp />
                      </S.ConnectionLink>
                    </S.Text>
                    <S.ConnectionLink href="tel:+73652777847" target="_blank" rel="noreferrer">
                      <S.Text>+7 (365) 2-777-847</S.Text>
                    </S.ConnectionLink>
                    <S.ConnectionLink href="tel:+74996774877" target="_blank" rel="noreferrer">
                      <S.Text>+7 (499) 6-774-877</S.Text>
                    </S.ConnectionLink>
                  </S.PhoneNumbers>
                </S.PhoneWrapper>
                <S.TimeWrapper>
                  <Icons.Clock />
                  Пн-Вс: С 9:00 до 20:00
                </S.TimeWrapper>
                <S.SocialLinks>
                  <a href="https://vk.com/probilets" target="_blank" rel="noreferrer">
                    <Icons.Vk />
                  </a>
                  <a href="https://www.instagram.com/probiletscom/" target="_blank" rel="noreferrer">
                    <Icons.Instagram />
                  </a>
                  <a href="https://ok.ru/probilets" target="_blank" rel="noreferrer">
                    <Icons.Odnoklassniki />
                  </a>
                  <a href="https://t.me/probilets" target="_blank" rel="noreferrer">
                    <Icons.Telegram />
                  </a>
                </S.SocialLinks>
                <Button label="Заказать звонок" onClick={openCallbackModal} />
              </S.ConnectionLinks>
            </S.ConnectionWrapper>
            <S.MobileAppWrapper>
              <S.GroupTitle>Мобильное приложение</S.GroupTitle>
              <S.StoreLinks>
                <a
                  href="https://apps.apple.com/by/app/белэкспресс-билеты-на-автобус/id1575385499"
                  onClick={() => ym("reachGoal", "appstore")}>
                  <S.StoreImage loading="lazy" src="/landing/app_store.svg" alt="App store" width={149} height={44} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.belexpress"
                  onClick={() => ym("reachGoal", "googleplay")}>
                  <S.StoreImage
                    loading="lazy"
                    src="/landing/google_play_store.svg"
                    alt="Google play"
                    width={149}
                    height={44}
                  />
                </a>
              </S.StoreLinks>
            </S.MobileAppWrapper>
          </S.AnotherContent>
        </S.FooterContentWrapper>
        <S.FooterContentWrapper>
          <S.PaymentServices>
            <S.PaymentServicesImage src="/payment-servece.svg" />
          </S.PaymentServices>
        </S.FooterContentWrapper>
      </S.TopFooter>
      <S.BottomFooter>
        <S.FooterContentWrapper>
          <S.CopyrightWrapper>
            <S.CopyrightText>
              Copyright © 2018 - {moment().year()}. Все права защищены. ООО «БелЭкспресс Бай». Республика Беларусь,
              г.Гомель, ул. Привокзальная 6А, УНП 491326303
            </S.CopyrightText>
          </S.CopyrightWrapper>
        </S.FooterContentWrapper>
      </S.BottomFooter>
      {!IS_DEVELOP && <Script src="https://assistant.g-leadbot.com/1713446641967" id="g-lead-enterpoint" />}
    </S.Footer>
  );
};
export default Footer;
