import { IconProps } from "@/icons/types";

const TelegramIcon = ({ size = "24px", fill = "#fff" }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m8 16c4.418278 0 8-3.581722 8-8s-3.581722-8-8-8-8 3.581722-8 8 3.581722 8 8 8zm-1.33048041-6.37283475 3.70945371-3.34749858c.1628067-.14449267-.0355533-.21495334-.25168-.08389334l-4.57819997 2.88833334-1.97753333-.61722c-.42706-.13076-.43012667-.42422.09588-.6352l7.706-2.9714c.3519533-.15978667.6916533.08453333.5572933.62321333l-1.3123333 6.1842c-.09164.43946-.3571667.54456-.72506667.3415667l-1.99909645-1.4769586-.96090355.9343586c-.11163333.1085333-.2.2-.4.2l.13613334-2.03953337z"
      fill={fill}
    />
  </svg>
);

export default TelegramIcon;
