import React, { CSSProperties } from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { Field, useField, type FieldProps } from "formik";
import moment from "moment";

import type { ReactDatePickerProps } from "react-datepicker";

import CustomDatePicker from "@/components/DatePicker";

import CustomError from "./CustomError";

const DatePicker = ({ field, form, ...props }: FieldProps) => (
  <CustomDatePicker
    isPure
    inputProps={{
      variant: "outline"
    }}
    {...props}
    selected={field.value !== null ? moment(field.value, "YYYY-MM-DD").toDate() : null}
    onChange={(e) => {
      if (e === null) {
        form.setFieldValue(field.name, null);
      } else {
        form.setFieldValue(field.name, moment(e).format("YYYY-MM-DD"));
      }
    }}
  />
);

interface IProps {
  name: string;
  label: string;
  styleFormWrapper?: CSSProperties;
}
const DateField = ({ name, label, ...props }: IProps & Omit<ReactDatePickerProps, "onChange">) => {
  const [, meta] = useField(name);
  return (
    <FormControl style={props.styleFormWrapper} isInvalid={!!meta.error && meta.touched}>
      <FormLabel>{label}</FormLabel>
      <Field name={name} component={DatePicker} {...props} />
      <CustomError name={name} />
    </FormControl>
  );
};

export default DateField;
