import styled from "@emotion/styled";
import { ClassNames, css } from "@emotion/react";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react18-input-otp";
import React from "react";

import { colors } from "@/styles/variables";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .form-control {
    padding: 6px 10px 5px 40px;
  }
`;
export const LabelWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;
export const DescriptionText = styled.div`
  margin-bottom: 30px;
  font-size: 12px;
  color: ${colors.neutral300};
  max-width: 400px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
