// country code
import F1 from "public/images/popularTrip/Rectangle 1.png";
import F2 from "public/images/popularTrip/Rectangle 1-1.png";
import F3 from "public/images/popularTrip/gomel.jpg";
import F4 from "public/images/popularTrip/Rectangle 1-3.png";
import F5 from "public/images/popularTrip/Rectangle 1-4.png";
import F6 from "public/images/popularTrip/Rectangle 1-5.png";

export const BY = "by";
export const RU = "ru";
export const KZ = "kz";
export const UA = "ua";

// currency abbreviation
export const BYN = "BYN";
export const RUB = "RUB";
export const KZT = "KZT";
export const UAH = "UAH";

//modal
export const ANIMATION_TIME = 300;

export const GET = "GET";
export const POST = "POST";
export const DELETE = "DELETE";

export const NO_PHOTO = "https://cms.belexpress.by/uploads/no_photo_1d048dcae1.png?updated_at=2022-10-21T04:50:11.781Z";

export const INTERESTED = "interesnoe";

export const KS_SESSION_TOKEN = "ksSessionToken";

export const popularTripsMock = [
  {
    id: 1,
    arrival: "Ростов-на-Дону",
    departure: "Минск",
    arrivalLatin: "rostov-on-don",
    departureLatin: "minsk",
    price: 163,
    arrivalCountry: "Россия",
    departureCountry: "Беларусь",
    imgSrc: F1,
    arrivalId: 2630,
    departureId: 2687
  },
  {
    id: 2,
    arrival: "Краснодар",
    departure: "Минск",
    arrivalLatin: "krasnodar",
    departureLatin: "minsk",
    price: 210,
    arrivalCountry: "Россия",
    departureCountry: "Беларусь",
    imgSrc: F2,
    arrivalId: 2700,
    departureId: 2687
  },
  {
    id: 3,
    arrival: "Анапа",
    departure: "Минск",
    arrivalLatin: "anapa",
    departureLatin: "minsk",
    price: 270,
    arrivalCountry: "Россия",
    departureCountry: "Беларусь",
    imgSrc: F6,
    arrivalId: 2574,
    departureId: 2687
  },
  {
    id: 4,
    arrival: "Москва",
    departure: "Минск",
    arrivalLatin: "moscow",
    departureLatin: "minsk",
    price: 66,
    arrivalCountry: "Россия",
    departureCountry: "Беларусь",
    imgSrc: F4,
    arrivalId: 2574,
    departureId: 2687
  },
  {
    id: 6,
    arrival: "Санкт-Петербург",
    departure: "Минск",
    arrivalLatin: "st-petersburg",
    departureLatin: "minsk",
    price: 83,
    arrivalCountry: "Россия",
    departureCountry: "Беларусь",
    imgSrc: F5,
    arrivalId: 2574,
    departureId: 2687
  },
  {
    id: 6,
    arrival: "Москва",
    departure: "Гомель",
    arrivalLatin: "moscow",
    departureLatin: "gomel",
    price: 100,
    arrivalCountry: "Россия",
    departureCountry: "Беларусь",
    imgSrc: F3,
    arrivalId: 2574,
    departureId: 2687
  }
];
