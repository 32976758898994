import React from "react";
import { Tooltip } from "@chakra-ui/react";

import Icons from "@/icons";
import { colors } from "@/styles/variables";

import * as S from "./styled";
const LabelTooltip = ({ content }: { content: string }) => {
  return (
    <Tooltip placement="right" hasArrow label={content} bg={colors.primary100} color={colors.neutral700}>
      <S.LabelTooltip>
        <Icons.Question fill={colors.primary700} sizeX="6px" sizeY="9px" />
      </S.LabelTooltip>
    </Tooltip>
  );
};

export default LabelTooltip;
