import styled from "@emotion/styled";

import { newColors as colors } from "@/styles/variables";

export const Header = styled.header`
  border-radius: 8px 8px 0px 0px;
  background: ${colors.white};
  padding: 20px 40px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media (max-width: 400px) {
    padding: 20px 15px;
  }
`;

export const Title = styled.header`
  font-size: 20px;
  color: ${colors.text};
  margin-right: 30px;
`;
export const CloseButton = styled.button`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.text};
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.15s;

  &:hover {
    svg {
      fill: ${colors.mainBlue};
    }
  }
`;
