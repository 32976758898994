import { IconProps } from "@/icons/types";

const SkypeIcon = ({ size = "24px", fill = "#555555", ...props }: IconProps) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      d="M19.3333 5.16667C21.8633 7.66 22.8167 11.1433 22.2178 14.3944C22.7189 15.2744 23 16.3011 23 17.3889C23 19.0097 22.3562 20.564 21.2101 21.7101C20.064 22.8562 18.5097 23.5 16.8889 23.5C15.8011 23.5 14.7744 23.2189 13.8944 22.7178C10.6433 23.3167 7.16 22.3633 4.66667 19.8333C2.13667 17.34 1.18333 13.8567 1.78222 10.6056C1.28111 9.72556 1 8.69889 1 7.61111C1 5.99034 1.64385 4.43596 2.7899 3.2899C3.93596 2.14385 5.49034 1.5 7.11111 1.5C8.19889 1.5 9.22556 1.78111 10.1056 2.28222C13.3567 1.68333 16.84 2.63667 19.3333 5.16667ZM12.0489 18.8067C15.5567 18.8067 17.3044 17.12 17.3044 14.8467C17.3044 13.3922 16.62 11.84 13.9678 11.2411L11.5356 10.7033C10.6067 10.4956 9.55556 10.2144 9.55556 9.34667C9.55556 8.46667 10.2889 7.85556 11.6333 7.85556C14.3589 7.85556 14.1022 9.72555 15.4589 9.72555C16.1678 9.72555 16.7789 9.31 16.7789 8.58889C16.7789 6.91444 14.1022 5.65556 11.8289 5.65556C9.37222 5.65556 6.74444 6.70667 6.74444 9.49333C6.74444 10.8378 7.22111 12.2678 9.86111 12.9278L13.1489 13.7589C14.1389 14.0033 14.3833 14.5533 14.3833 15.0667C14.3833 15.8978 13.5522 16.7167 12.0489 16.7167C9.10333 16.7167 9.50667 14.4556 7.93 14.4556C7.22111 14.4556 6.70778 14.9444 6.70778 15.6411C6.70778 16.9978 8.33333 18.8067 12.0489 18.8067Z"
      fill={fill}
    />
  </svg>
);

export default SkypeIcon;
