import { combineReducers } from "redux";

import currency from "@/store/slices/currency";
import searchForm from "@/store/slices/searchForm";
import globalData from "@/store/slices/globalData";
import user from "@/store/slices/user";
import searchResults from "@/store/slices/searchResults";
import selectedTracks from "@/store/slices/selectedTracks";
import payment from "@/store/slices/payment";

export default combineReducers({
  user,
  searchForm,
  searchResults,
  globalData,
  selectedTracks,
  payment,
  currency
});
