export const CloseIcon = (props) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.505074 0.505013C0.778441 0.231646 1.22166 0.231646 1.49502 0.505013L5.00005 4.01004L8.50507 0.505013C8.77844 0.231646 9.22166 0.231646 9.49502 0.505013C9.76839 0.77838 9.76839 1.2216 9.49502 1.49496L5.99 4.99999L9.49502 8.50501C9.76839 8.77838 9.76839 9.2216 9.49502 9.49496C9.22166 9.76833 8.77844 9.76833 8.50507 9.49496L5.00005 5.98994L1.49502 9.49496C1.22166 9.76833 0.778441 9.76833 0.505074 9.49496C0.231707 9.2216 0.231707 8.77838 0.505074 8.50501L4.0101 4.99999L0.505074 1.49496C0.231707 1.2216 0.231707 0.77838 0.505074 0.505013Z"
      fill="#555555"
      fillOpacity="0.6"
    />
  </svg>
);

export const ExpandIcon = (props) => (
  <svg width="20" height="7" viewBox="0 0 20 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.37695 0.963727L0.237682 5.55565C0.0851116 5.7157 0 5.92833 0 6.14945C0 6.37056 0.0851116 6.58319 0.237682 6.74324L0.248009 6.75357C0.321972 6.83142 0.411 6.89342 0.509678 6.93578C0.608357 6.97815 0.714622 6.99999 0.822009 6.99999C0.929397 6.99999 1.03566 6.97815 1.13434 6.93578C1.23302 6.89342 1.32205 6.83142 1.39601 6.75357L10.0017 2.72101L18.604 6.75357C18.678 6.83142 18.767 6.89342 18.8657 6.93578C18.9643 6.97815 19.0706 6.99999 19.178 6.99999C19.2854 6.99999 19.3916 6.97815 19.4903 6.93578C19.589 6.89342 19.678 6.83142 19.752 6.75357L19.7623 6.74324C19.9149 6.58319 20 6.37056 20 6.14945C20 5.92833 19.9149 5.7157 19.7623 5.55565L10.6231 0.963727C10.5427 0.87937 10.446 0.812212 10.3389 0.766325C10.2318 0.720439 10.1165 0.696777 10 0.696777C9.88348 0.696777 9.76818 0.720439 9.66108 0.766325C9.55398 0.812212 9.45732 0.87937 9.37695 0.963727Z"
      fill="#555555"
      fill-opacity="0.4"
    />
  </svg>
);
