import { IconProps } from "@/icons/types";

const CloseIcon = ({ size = "14px", fill = "#000", ...props }: IconProps) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 14 14"
    fill="none"
    {...props}>
    <path
      fill={fill}
      d="M6.99997 8.90387L2.47577 4.26018C2.13814 3.91328 1.59077 3.91328 1.25319 4.26018C0.915604 4.60704 0.915604 5.16949 1.25319 5.51643L6.34527 10.743C6.52516 10.9278 6.76445 11.011 6.99997 10.9988C7.23554 11.011 7.47481 10.9278 7.65472 10.743L12.7468 5.51643C13.0844 5.16951 13.0844 4.60711 12.7468 4.26018C12.4092 3.91326 11.8618 3.91328 11.5243 4.26018L6.99997 8.90387Z"
    />
  </svg>
);

export default CloseIcon;
