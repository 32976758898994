import React, { useState } from "react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/menu";
import Link from "next/link";
import { Collapse, forwardRef } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import * as S from "./styled";

const ButtonText = forwardRef((props, ref) => (
  <S.ButtonText ref={ref} {...props}>
    {props.children}
    {props["aria-expanded"] ? <ChevronUpIcon /> : <ChevronDownIcon />}
  </S.ButtonText>
));

const DropdownLinks = ({ item }) => {
  // const width1000 = useMediaQuery("(max-width: 1100px)");
  const [open, setOpen] = useState(false);

  if (item.path) {
    return (
      <Link href={item.path} target={item.target || "_self"} rel={item.rel}>
        {item.title}
      </Link>
    );
  }
  return (
    <>
      <S.MobileWrapper>
        {!!item.path && (
          <Link href={item.path} target={item.target || "_self"} rel={item.rel}>
            {item.title}
          </Link>
        )}
        <div>
          <S.ButtonText onClick={() => setOpen((prev) => !prev)}>
            {item.title} {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </S.ButtonText>
          <div>
            <Collapse in={open}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {item.links.map((link) => (
                  <Link
                    key={link.path}
                    style={{ borderRadius: "4px", padding: "4px 10px" }}
                    href={link.path}
                    target={link.target || "_self"}
                    rel={link.rel}>
                    {link.label}
                  </Link>
                ))}
              </div>
            </Collapse>
          </div>
        </div>
      </S.MobileWrapper>
      <S.DesctopWrapper>
        <Menu>
          <MenuButton as={ButtonText}>{item.title}</MenuButton>
          <MenuList style={{ padding: "10px" }}>
            {item.links.map((link) => (
              <MenuItem
                key={link.path}
                style={{ borderRadius: "4px", padding: "4px 10px" }}
                as={Link}
                href={link.path}
                target={link.target || "_self"}
                rel={link.rel}>
                {link.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </S.DesctopWrapper>

    </>
  );
};

export default DropdownLinks;
