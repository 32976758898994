import React, { memo } from "react";
import { Formik, Form, type FormikHelpers } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";

import { NinetyRingWithBg } from "react-svg-spinners";
import { toast } from "react-toastify";

import Modal from "@/components/Modal";
import { useAppDispatch, useAppSelector } from "@/store/store";
import Button from "@/components/Buttons";
import { InputField } from "@/components/FormComponents";
import { setCallbackModalVisible } from "@/store/slices/globalData";
import { sendContactMail } from "@/utils/requests/mails";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Заполните адрес электронной почты"),
  name: Yup.string().required("Поле обязательно для заполнения"),
  textarea: Yup.string().min(10, "Минимальное сообщение не короче 10 символов").required("Введите сообщение"),
  phone: Yup.string()
    .phone(["RU", "BY", "KZ", "UA"], "Заполните номер телефона")
    .required("Поле обязательно для заполнения")
});

interface InitialValue {
  name: string;
  phone: string;
  email: string;
  textarea: string;
}
const CallbackModal = () => {
  const callbackModal = useAppSelector((state) => state.globalData.callbackModal);
  const dispatch = useAppDispatch();
  const initialValue: InitialValue = {
    name: "",
    phone: "",
    email: "",
    textarea: ""
  };

  const sendForm = async (values: InitialValue, { resetForm }: FormikHelpers<InitialValue>) => {
    try {
      const { email, name, phone, textarea } = values;
      const phoneNumber = phone.replace(/[\(\)\s-]/g, "");

      const sendData = {
        email,
        name,
        phone: phoneNumber,
        message: textarea
      };

      await sendContactMail(sendData);

      resetForm();
      toast.success("Ваше сообщение успешно отправлено");
      dispatch(setCallbackModalVisible(false));
    } catch (e) {
      toast.error("Ошибка отправки сообщения");
    }
  };

  const handleCloseModal = () => {
    dispatch(setCallbackModalVisible(false));
  };

  return (
    <Modal
      onClose={handleCloseModal}
      opened={callbackModal}
      title="Отправьте свои данные и мы перезвоним вам"
      footerVisible={false}>
      <Formik initialValues={initialValue} onSubmit={sendForm} validationSchema={validationSchema}>
        {({ isSubmitting }) => (
          <Form style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <InputField name="name" label="Имя" placeholder="Имя" />
            <InputField name="phone" label="Телефон" />
            <InputField name="email" label="E-mail" placeholder="E-mail" />
            <InputField name="textarea" label="Сообщение" placeholder="Введите сообщение..." />
            <Button type="submit" disabled={isSubmitting} maxWidth="initial">
              {isSubmitting ? <NinetyRingWithBg color="#fff" /> : "Отправить"}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default memo(CallbackModal);
