import React from "react";

import Portal from "../Portal";

import Footer from "./Footer";
import Header from "./Header";
import Layout from "./Layout";
import { useMount } from "./useMount";
import * as S from "./styled";

interface IProps {
  onClose: () => void;
  children: React.ReactNode;
  opened: boolean;
  title: string;
  footer?: React.ReactNode;
  footerVisible: boolean;
}

const Modal = ({ opened, onClose, children, title, footer, footerVisible }: IProps) => {
  const { mounted } = useMount({ opened });

  if (!mounted) {
    return null;
  }

  return (
    <Portal>
      <Layout onClose={onClose} opened={opened}>
        <Header title={title} onClick={onClose} />
        <S.ContentWrapper footerVisible={footerVisible}>{children}</S.ContentWrapper>
        <Footer footerVisible={footerVisible}>{footer}</Footer>
      </Layout>
    </Portal>
  );
};

export default Modal;
