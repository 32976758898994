import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sendApiRequest } from "@/core/Api";
import config from "@/core/config";

interface InitialState {
  data: null;
}

const initialState: InitialState = {
  data: null
};

interface PaymentData {
  token: string;
}
export const getIntercarsPaymentInfo = createAsyncThunk(
  "paymentInfo/getIntercarsPaymentInfo",
  async (token: string) => {
    const data = {
      token
    };

    return await sendApiRequest("getIntercarsPaymentData", "post", { data }, true);
  }
);

export const getRosbiletPaymentInfo = createAsyncThunk("paymentInfo/getRosbiletPaymentInfo", async (token: string) => {
  const data = {
    token,
    api_key: config.apiKey
  };

  const response = await fetch(`${config.apiBasePath}getRosbiletPaymentData`, {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });

  return await response.json();
});

export const getPaymentInfo = createAsyncThunk("paymentInfo/getPaymentInfo", async (token: string) => {
  const data = {
    token,
    userToken: localStorage.getItem("userToken")
  };

  return await sendApiRequest("getPaymentData", "post", { data }, true);
});

const paymentSlice = createSlice({
  name: "paymentInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIntercarsPaymentInfo.fulfilled, (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    });

    builder.addCase(getRosbiletPaymentInfo.fulfilled, (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    });

    builder.addCase(getPaymentInfo.fulfilled, (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    });
  }
});

export default paymentSlice.reducer;
