import React, { useEffect, useState } from "react";
import App, { AppContext, AppProps } from "next/app";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import ym, { YMInitializer } from "@appigram/react-yandex-metrika";
import { Provider } from "react-redux";
import moment from "moment/moment";
import "moment/locale/ru";
import ru from "date-fns/locale/ru";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { NextPageContext } from "next";
import { ChakraProvider } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import TagManager from "react-gtm-module";
import { DefaultSeo } from "next-seo";
import Script from "next/script";
import { Bounce, ToastContainer } from "react-toastify";

import { AppStore, useAppDispatch, wrapper } from "@/store/store";
import { formatDomainZone, IS_DEVELOP, setOverflow } from "@/utils";
import { getGeo } from "@/core/Api";
import { BY, RU } from "@/utils/constants";
import { globalStyles } from "@/styles/global";
import themeConfig from "@/styles/theme.config";
import MobileAppPopup from "@/modals/MobileAppPopup";
import LocationModal from "@/modals/LocationModal";
import AuthModal from "@/modals/AuthModal";
import { setClientID } from "@/store/slices/user";
import MailRuScript from "@/utils/mailru";
import CallbackModal from "@/modals/CallbackModal";

import AppLayout from "../components/AppLayout/AppLayout";
import config from "../core/config";
import SpecialTagWrapper from "../components/utils";

import "react-toastify/dist/ReactToastify.css";
import { fetchCities, fetchDocuments, fetchNationality } from "@/store/slices/globalData";

const tagManagerArgs = {
  gtmId: "GTM-M85RQJS9"
};

interface IPropsMeta {
  name: string;
  content: string;
}

interface IProps extends AppProps {
  host: string;
  domain: string;
}
interface MyCtx extends NextPageContext {
  store: AppStore;
  isServer: boolean;
}
export interface MyAppContext extends AppContext {
  ctx: MyCtx;
}

const currLocation = "currentLocation";

const DynamicComponentWithNoSSR = dynamic<React.ComponentProps<typeof MailRuScript>>(() => import("@/utils/mailru"), {
  ssr: false
});
const Meta = ({ name, content }: IPropsMeta) => <meta name={name} content={content} />;

const MyApp = ({ Component, pageProps, host, domain, ...rest }: IProps) => {
  const [modalMobile, setModalMobile] = useState(false);
  const [modalLocation, setModalLocation] = useState(false);
  const [geolocation, setGeolocation] = useState({
    country: "",
    code: ""
  });
  const router = useRouter();

  const getYmID = () => {
    switch (domain) {
      case BY:
        return 93071083;
      case RU:
        return 93071102;
      default:
        return 93071083;
    }
  };

  moment.locale("ru");
  registerLocale("ru", ru);
  setDefaultLocale("ru");

  useEffect(() => {
    setModalMobile(detectMobile());
    location()
      .then(({ ok }) => {
        if (ok) setOverflow("hidden");
      })
      .catch(({ ok }) => {
        if (!ok) setOverflow("auto");
      });

    if (!IS_DEVELOP) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    if (!IS_DEVELOP) {
      Router.events.on("routeChangeComplete", (url) => {
        ym("hit", url);
      });
    }

    if (!IS_DEVELOP && domain === BY) {
      import("react-facebook-pixel")
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init("967262294551753");
          ReactPixel.pageView();

          router.events.on("routeChangeComplete", () => {
            ReactPixel.pageView();
          });
        });
    }
  }, [router.events]);

  const closePopup = () => {
    setModalMobile(false);
    localStorage.setItem("mobilePopupCloseDate", String(moment().valueOf()));
  };
  const detectMobile = () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isMobile) {
      const mobilePopupClosedDate = localStorage.getItem("mobilePopupCloseDate");
      if (mobilePopupClosedDate) {
        return moment().valueOf() - Number(mobilePopupClosedDate) > 5184000000; // 60 days
      }
      return isMobile;
    }
    return false;
  };
  const location = (): Promise<{ ok: boolean }> => {
    return new Promise(async (resolve, reject) => {
      if (!sessionStorage.getItem(currLocation)) {
        const geolocation = await getGeo();
        const domain = formatDomainZone(window.location.host);

        if (geolocation.code !== domain) {
          setGeolocation(geolocation);
          setModalLocation(true);
          sessionStorage.setItem(currLocation, geolocation.code ?? "missingGeo");

          resolve({ ok: true });
        } else {
          reject({ ok: false });
        }
      }
    });
  };
  const closePopupLocation = () => {
    setModalLocation(false);
    setOverflow("auto");
  };

  return (
    <>
      <Head>
        <SpecialTagWrapper
          domain={domain}
          renderBY={
            <>
              <Meta name="facebook-domain-verification" content="jlitqeuy3gjfyhyxdl8gpxety699sk" />
            </>
          }></SpecialTagWrapper>
      </Head>
      <DefaultSeo
        canonical={`https://${host?.split(":")[0]}${router?.asPath?.split("?")[0]}`}
        openGraph={{ url: `https://${host?.split(":")[0]}${router?.asPath?.split("?")[0]}` }}
      />
      <SpecialTagWrapper domain={domain}>
        <DynamicComponentWithNoSSR domain={domain} />
        <YMInitializer accounts={[getYmID()]} version="2" options={config.yandexMetric} />
      </SpecialTagWrapper>
      <ChakraProvider theme={themeConfig}>
        {globalStyles}
        {modalLocation && (
          <LocationModal closeModal={closePopupLocation} country={geolocation.country} code={geolocation.code} />
        )}
        <MobileAppPopup close={closePopup} open={modalMobile} />
        <AuthModal />
        <CallbackModal />
        <AppLayout host={host}>
          <Component {...pageProps} />
        </AppLayout>
        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          newestOnTop
          closeOnClick
          rtl={false}
          theme="colored"
          transition={Bounce}
        />
      </ChakraProvider>
      <Script id="vk-pixel">
        {
          "!function(){var t=document.createElement(\"script\");t.type=\"text/javascript\",t.async=!0,t.src='https://vk.com/js/api/openapi.js?171',t.onload=function(){VK.Retargeting.Init(\"VK-RTRG-1868373-7R1tK\"),VK.Retargeting.Hit()},document.head.appendChild(t)}();"
        }
      </Script>
      <noscript>
        <img src="https://vk.com/rtrg?p=VK-RTRG-1868373-7R1tK" style={{ position: "fixed", left: "-999px" }} alt="" />
      </noscript>
    </>
  );
};

MyApp.getInitialProps = async (appContext: MyAppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const ctx = appContext.ctx;
  const host = ctx?.req?.headers.host;

  const domain = formatDomainZone(host);
  const request = [
    ctx.store.dispatch(fetchCities()),
    ctx.store.dispatch(fetchNationality()),
    ctx.store.dispatch(fetchDocuments())
  ];
  await Promise.all(request);

  return { ...appProps, host, domain };
};

export default wrapper.withRedux(MyApp);
