import TelegramIcon from "./TelegramIcon";
import MailIcon from "./MailIcon";
import RouteIcon from "./RouteIcon";
import QuestionIcon from "./QuestionIcon";
import SecondaryArrow from "./SecondaryArrow";
import SeatIcon from "./SeatIcon";
import ArrowIcon from "./ArrowIcon";
import ViberIcon from "./ViberIcon";
import VkIcon from "./VkIcon";
import InstagramIcon from "./InstagramIcon";
import OdnoklassnikiIcon from "./OdnoklassnikiIcon";
import WhatsappIcon from "./WhatsappIcon";
import PhoneIcon from "./PhoneIcon";
import CloseIcon from "./CloseIcon";
import UserIcon from "./UserIcon";
import RatingIcon from "./RatingIcon";
import ClockIcon from "./ClockIcon";
import SortIcon from "./SortIcon";
import EditIcon from "./EditIcon";
import InfoIcon from "./InfoIcon";
import Contacts from "./contacts";
import Flags from "./flags";

export default {
  Viber: ViberIcon,
  Vk: VkIcon,
  Edit: EditIcon,
  Instagram: InstagramIcon,
  Odnoklassniki: OdnoklassnikiIcon,
  Whatsapp: WhatsappIcon,
  Phone: PhoneIcon,
  Close: CloseIcon,
  Arrow: ArrowIcon,
  User: UserIcon,
  Seat: SeatIcon,
  Question: QuestionIcon,
  Rating: RatingIcon,
  Clock: ClockIcon,
  SecondaryArrow: SecondaryArrow,
  Sort: SortIcon,
  Route: RouteIcon,
  Info: InfoIcon,
  Mail: MailIcon,
  Telegram: TelegramIcon,
  Contacts: Contacts,
  Flags: Flags
};
