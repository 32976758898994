import styled from "@emotion/styled";

import { colors } from "@/styles/variables";

export const ContentWrapper = styled.main<{ footerVisible: boolean }>`
  background-color: ${colors.white};
  padding: 15px 40px 30px;
  border-radius: ${(props) => (props.footerVisible ? "0" : "0 0 4px 4px")};

  @media (max-width: 400px) {
    padding: 15px 15px 30px;
  }
`;
