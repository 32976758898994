import React, { useEffect, useState } from "react";
import Image from "next/image";

import { CloseIcon } from "@/modals/MobileAppPopup/Icons";
import AppStoreImage from "public/landing/app_store-main.png";
import GooglePlayImage from "public/landing/google_play_store.png";
import Button from "@/components/Buttons";
import MobileAppImage from "public/images/mobile-app-image.png";

import * as S from "./styled";

interface IProps {
  open: boolean;
  close: () => void;
}
const MobileAppPopup = ({ open, close }: IProps) => {
  const [link, setLink] = useState("");

  const [isExpanded, setExpanded] = useState(false);

  const handleClose = () => {
    setExpanded(false);
    close();
  };

  const handleExpand = () => {
    setExpanded(!isExpanded);
  };
  useEffect(() => {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      setLink(
        "https://apps.apple.com/ru/app/%D0%B1%D0%B5%D0%BB%D1%8D%D0%BA%D1%81%D0%BF%D1%80%D0%B5%D1%81%D1%81-%D0%B1%D0%B8%D0%BB%D0%B5%D1%82%D1%8B-%D0%BD%D0%B0-%D0%B0%D0%B2%D1%82%D0%BE%D0%B1%D1%83%D1%81/id1575385499"
      );
    }
    if (navigator.userAgent.match(/Android/i)) {
      setLink("https://play.google.com/store/apps/details?id=com.belexpress");
    }
  }, []);

  if (!open) return null;

  return (
    <>
      <S.Backdrop isExpand={isExpanded} onClick={() => setExpanded(false)} />
      <S.BottomModalSheet isExpand={isExpanded}>
        <S.Header>
          <S.ExpandButton onClick={handleExpand}>
            <S.ExpandIcon isExpand={isExpanded} />
          </S.ExpandButton>
          <S.CloseButton onClick={handleClose}>
            <CloseIcon />
          </S.CloseButton>
        </S.Header>

        <S.Content>
          <S.FirstPoint isExpand={isExpanded}>
            <S.FirstPointText>Установите наше приложение</S.FirstPointText>
            <S.FirstPointLinks>
              <a href="https://apps.apple.com/by/app/probilets/id1575385499" target="_blank">
                <Image
                  src={AppStoreImage}
                  loading="lazy"
                  placeholder="blur"
                  alt="search bus apple application"
                  width={120}
                  height={35}
                  sizes="100%"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.belexpress" target="_blank">
                <Image
                  src={GooglePlayImage}
                  loading="lazy"
                  placeholder="blur"
                  alt="search bus android application"
                  width={120}
                  height={35}
                  sizes="100%"
                />
              </a>
            </S.FirstPointLinks>
          </S.FirstPoint>
          <S.SecondPoint isExpand={isExpanded}>
            <S.SecondPointTitle>Приложение Probilets</S.SecondPointTitle>
            <S.SecondPointText>
              Удобный поиск. Бронирование в течение нескольких минут. Оплата билета в 2 клика.
            </S.SecondPointText>
            <S.ButtonsWrapper>
              <Button label="Установить сейчас" isLink path={link} maxWidth="inithial" onClick={handleClose} />
              <S.ButtonText onClick={() => setExpanded(false)}>Спасибо, не сейчас</S.ButtonText>
            </S.ButtonsWrapper>
            <S.ImageWrapper>
              <Image
                src={MobileAppImage}
                alt="probilets mobile application"
                width={380}
                height={401}
                style={{
                  objectFit: "contain"
                }}
              />
            </S.ImageWrapper>
          </S.SecondPoint>
        </S.Content>
      </S.BottomModalSheet>
    </>
  );
};

export default MobileAppPopup;
