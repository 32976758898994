import { type PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import config from "@/core/config";
import { currencyFormatter } from "@/utils/currencyFunctions";
import httpClient from "@/utils/http";

export type ICurrencyList = ICurrency[];
export interface ICurrencyState {
  currencyAbbreviation: string;
  currencyRate: number;
  currencyList: ICurrencyList;
}

interface MyKnownError {
  errorMessage: string;
}
export type AbbreviationType = "RUB" | "BYN" | "UAH" | "KZT";

export interface ICurrency {
  Cur_ID: number;
  Date: string;
  Cur_Abbreviation: string;
  Cur_Scale: number;
  Cur_Name: string;
  Cur_OfficialRate: number;
}

export interface PayloadCurrentCurrency {
  currencyList: ICurrencyList;
  abbreviation: AbbreviationType;
}

const initialState: ICurrencyState = {
  currencyAbbreviation: "",
  currencyRate: 0,
  currencyList: []
};

export const fetchCurrency = createAsyncThunk<ICurrencyList>("currency/fetchCurrency", async (_, thunkAPI) => {
  const response = await httpClient.get("helpers/currency-all");
  if (response.status === 400) {
    return thunkAPI.rejectWithValue(response as MyKnownError);
  }
  return response.data;
});

const currency = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setCurrentCurrency: (state, action: PayloadAction<PayloadCurrentCurrency>) => {
      const { currencyAbbreviation, currencyRate } = currencyFormatter(
        action.payload.currencyList,
        action.payload.abbreviation
      );

      state.currencyRate = currencyRate;
      state.currencyAbbreviation = currencyAbbreviation;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrency.fulfilled, (state, { payload }) => {
      state.currencyList = payload;
    });
  }
});

export const { setCurrentCurrency } = currency.actions;

export default currency.reducer;
