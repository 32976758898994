import React, { CSSProperties } from "react";
import { FormControl } from "@chakra-ui/react";
import { Field, useField } from "formik";

import type { Props } from "react-select";
import type { AsyncProps } from "chakra-react-select";

import LabelTooltip from "@/components/FormComponents/LabelTooltip";

import CustomSelect from "./CustomSelect";
import CustomError from "./CustomError";
import * as S from "./styled";

interface IProps {
  name: string;
  label: string;
  tooltipContent?: string;
  styleContainer?: CSSProperties;
  async?: boolean;
}
const SelectField = ({
  name,
  label,
  tooltipContent,
  styleContainer,
  ...props
}: IProps & (Props | AsyncProps<any, any, any>) & { valueKey?: string }) => {
  const [, meta] = useField(name);
  return (
    <FormControl style={styleContainer} isInvalid={!!meta.error && meta.touched}>
      <S.FormLabel>
        {label}
        {!!tooltipContent && <LabelTooltip content={tooltipContent} />}
      </S.FormLabel>
      <Field name={name} component={CustomSelect} {...props} />
      <CustomError name={name} />
    </FormControl>
  );
};

export default SelectField;
