import React from "react";
import { ClassNames, css } from "@emotion/react";
import DefaultOtpInput from "react18-input-otp";

import type { FieldProps } from "formik";

import { colors } from "@/styles/variables";

const OtpInputWrapperCss = css`
  gap: 20px;
  @media (max-width: 500px) {
    gap: 5px;
    padding: 0;
  }
`;
const OtpInputCss = css`
  border-radius: 8px;
  height: 40px;
  width: 40px !important;
  border: 1px solid ${colors.neutral300};
  margin: 4px;
  font-size: 18px;
  box-sizing: border-box;
  color: ${colors.neutral900};
  background-color: white;
  outline: none;
  user-select: none;
`;
export const StyledOtpInput = (props: Record<string, any>) => (
  <ClassNames>
    {({ css }) => <DefaultOtpInput containerStyle={css(OtpInputWrapperCss)} {...props} inputStyle={css(OtpInputCss)} />}
  </ClassNames>
);

const CustomOtpInput = ({ field, form, ...props }: FieldProps & { isInvalid: boolean }) => {
  return (
    <div style={props.styleOtpWrapper}>
      <StyledOtpInput
        value={field.value}
        onChange={(otp: string) => form.setFieldValue(field.name, otp)}
        numInputs={5}
        focusStyle={{
          border: `1px solid ${colors.primary500}`
        }}
        {...props}
      />
    </div>
  );
};

export default CustomOtpInput;
