import { IconProps } from "@/icons/types";

const Russia = ({ size = "16px", ...props }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_8_435)">
      <path
        d="M16 12C16 12.4715 15.8127 12.9237 15.4793 13.2571C15.1459 13.5905 14.6937 13.7778 14.2222 13.7778H1.77778C1.30628 13.7778 0.854097 13.5905 0.520699 13.2571C0.187301 12.9237 0 12.4715 0 12V10.2222H16V12Z"
        fill="#CE2028"
      />
      <path d="M0 5.77777H16V10.2222H0V5.77777Z" fill="#22408C" />
      <path
        d="M14.2222 2.22223H1.77778C1.30628 2.22223 0.854097 2.40953 0.520699 2.74293C0.187301 3.07633 0 3.52851 0 4.00001L0 5.77778H16V4.00001C16 3.52851 15.8127 3.07633 15.4793 2.74293C15.1459 2.40953 14.6937 2.22223 14.2222 2.22223Z"
        fill="#EEEEEE"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_435">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Russia;
