import { type FieldProps } from "formik";
import { AsyncSelect, type AsyncProps, type ChakraStylesConfig, Select } from "chakra-react-select";
import React from "react";
import { isArray } from "lodash";

import type { Props } from "react-select";

import { newColors as colors } from "@/styles/variables";

const chakraStyles: ChakraStylesConfig = {
  control: (provided) => ({
    ...provided,
    backgroundColor: colors.white,
    borderColor: colors.border
  }),
  container: (provided) => ({
    ...provided
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "10px"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#0073ff" : "#fff",
    borderRadius: "6px",
    color: state.isSelected ? "#ffffff" : colors.text
  })
};

const CustomSelect = ({
  field,
  form,
  ...props
}: FieldProps & (Props | AsyncProps<any, any, any>) & { valueKey?: string; async?: boolean }) => {
  if (props.async) {
    return (
      <AsyncSelect
        {...field}
        {...props}
        size="md"
        cacheOptions
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        chakraStyles={chakraStyles}
        value={field.value}
        placeholder="Выберите..."
        onChange={(option) => form.setFieldValue(field.name, props.valueKey ? option[`${props.valueKey}`] : option)}
        variant="outline"
        loadingMessage={() => <div>Загрузка...</div>}
        noOptionsMessage={() => <div>Данных не найдено</div>}
        useBasicStyles
      />
    );
  }
  return (
    <Select
      {...field}
      {...props}
      size="md"
      chakraStyles={chakraStyles}
      value={isArray(props.options) ? props.options.find((item) => item.value === field.value) : null}
      placeholder="Выберите..."
      onChange={(option) => form.setFieldValue(field.name, props.valueKey ? option[`${props.valueKey}`] : option)}
      variant="outline"
      useBasicStyles
    />
  );
};
export default CustomSelect;
