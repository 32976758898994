import { colors } from "@/styles/variables";

const baseStyle = {
  // select the indicator part
  indicator: {
    "&[data-status=active]": {
      borderColor: colors.primary600,
      color: colors.primary600
    },
    "&[data-status=complete]": {
      bg: colors.primary600,
      color: "#fff"
    }
  },
  separator: {
    bg: colors.neutral200,
    "&[data-status=complete]": {
      bg: colors.primary600
    }
  }
};

const stepperTheme = {
  baseStyle
};

export default stepperTheme;
