import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { newColors as colors } from "@/styles/variables";

interface IProps {
  variant: "minimal" | "default" | "primary" | "secondary";
  disabled: boolean;
}
export const RoundButton = styled.button`
  display: flex;
  position: fixed;
  right: 20px;
  bottom: 20px;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: rgb(102 153 204 / 40%) 1px 1px 10px;

  &::before {
    content: "1";
    position: absolute;
    bottom: -4px;
    left: -4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffe000;
    color: black;
    font-size: 13px;
  }

  svg {
    rotate: 180deg;
  }
`;

const MinimalButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  transition: opacity 0.3s;
  border: 2px solid ${colors.mainBlue};
  border-radius: 8px;
  background-color: transparent;
  color: ${colors.mainBlue};
  font-family: Inter, sans-serif;
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    opacity: 0.7;
  }
`;

const DefaultButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  width: 100%;
  transition: background 0.3s;
  border: none;
  border-radius: 8px;
  background-image: linear-gradient(
    135deg,
    rgba(3, 114, 255, 1) 0%,
    rgba(131, 96, 255, 1) 57%,
    rgba(185, 86, 229, 1) 100%
  );
  background-position: center center;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
  text-transform: uppercase;

  & span {
    text-wrap: nowrap;
    color: inherit;
  }

  &:hover {
    background-image: linear-gradient(
      135deg,
      rgba(3, 114, 255, 1) 0%,
      rgba(131, 96, 255, 1) 45%,
      rgba(185, 86, 229, 1) 91%
    );
    color: #ffffff;
  }

  &:disabled {
    cursor: not-allowed;
  }
  &:active {
    background-image: linear-gradient(
      135deg,
      rgba(3, 114, 255, 1) 0%,
      rgba(131, 96, 255, 1) 0%,
      rgba(185, 86, 229, 1) 100%
    );
  }
`;

export const Button = styled.button(({ variant }: IProps) => {
  switch (variant) {
    case "minimal":
      return MinimalButton;
    default:
      return DefaultButton;
  }
});
