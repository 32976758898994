import { SVGProps } from "react";

const SecondaryArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25.1009 8.10064L25.1009 8.10068L19.1021 14.0995C19.0245 14.1824 18.9311 14.2489 18.8274 14.2951C18.7231 14.3416 18.6106 14.3665 18.4965 14.3685C18.3823 14.3706 18.269 14.3496 18.1631 14.3068C18.0573 14.2641 17.9611 14.2004 17.8804 14.1197C17.7997 14.039 17.7361 13.9429 17.6933 13.837C17.6506 13.7312 17.6296 13.6178 17.6316 13.5037C17.6336 13.3896 17.6586 13.277 17.705 13.1727C17.7513 13.069 17.8178 12.9756 17.9007 12.898L22.451 8.3477L1.75 8.15106C1.74983 8.15106 1.74966 8.15106 1.74949 8.15106C1.52424 8.15092 1.30824 8.06138 1.14896 7.9021C0.989553 7.74269 0.9 7.52649 0.9 7.30106C0.9 7.07562 0.989553 6.85942 1.14896 6.70002C1.30836 6.54061 1.52457 6.45106 1.75 6.45105L1.75095 6.45106L22.4464 6.64765L17.9007 2.10189C17.8178 2.02431 17.7513 1.93092 17.705 1.82721C17.6586 1.72294 17.6336 1.61038 17.6316 1.49625C17.6296 1.38212 17.6506 1.26876 17.6933 1.16292C17.7361 1.05708 17.7997 0.960935 17.8804 0.880219C17.9611 0.799505 18.0573 0.735874 18.1631 0.693124C18.269 0.650373 18.3823 0.629379 18.4965 0.631393C18.6106 0.633407 18.7231 0.658386 18.8274 0.704844C18.9311 0.751055 19.0245 0.817571 19.1021 0.900462L25.1009 6.89926L25.1009 6.8993C25.2601 7.05868 25.3495 7.27472 25.3495 7.49997C25.3495 7.72522 25.2601 7.94126 25.1009 8.10064Z"
        fill="#8360FF"
        stroke="#8360FF"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default SecondaryArrow;
