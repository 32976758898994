import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { sendApiRequest } from "@/core/Api";

interface InitialState {
  results: any[]; // Replace "any" with the actual result type or interface
  avia: any[] | null; // Replace "any" with the actual result type or interface
  clear: boolean;
  resultsFuture: {}; // Specify a suitable type or interface for resultsFuture
}

const initialState: InitialState = {
  results: [],
  avia: null,
  clear: true,
  resultsFuture: {}
};

interface SearchParams {
  // Define your searchParams properties and types here
}

export const getSearchResults = createAsyncThunk(
  "searchResults/getSearchResults",
  async (searchParams: SearchParams) => {
    searchParams.token = localStorage.getItem("userToken");

    const searchResults = await sendApiRequest("getSearchBuses", "post", { data: searchParams }, true);

    return searchResults;
  }
);

export const getSearchResultsFuture = createAsyncThunk(
  "searchResults/getSearchResultsFuture",
  async (searchParams: SearchParams) => {
    const searchResults = await sendApiRequest("getSearchBusesFuture", "post", { data: searchParams }, true);

    return searchResults;
  }
);

const searchResultsSlice = createSlice({
  name: "searchResults",
  initialState,
  reducers: {
    clearSearchResults: (state) => {
      state.results = [];
      state.clear = true;
    },
    saveSearchResult: (state, { payload }) => {
      state.avia = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchResults.fulfilled, (state, { payload }) => {
      state.results = payload;
      state.clear = false;
    });

    builder.addCase(getSearchResultsFuture.fulfilled, (state, { payload }) => {
      state.resultsFuture = payload;
    });
  }
});

export const { saveSearchResult } = searchResultsSlice.actions;
export default searchResultsSlice.reducer;
