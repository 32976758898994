import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  td: {
    paddingTop: "12px",
    paddingBottom: "12px"
  }
});

const tableTheme = defineMultiStyleConfig({ baseStyle });
export default tableTheme;
