export const linksList = [
  {
    title: "Автобусы",
    links: [
      { label: "Маршруты", path: "/routes" },
      { label: "Автобусы онлайн", path: "/online" }
    ]
  },
  { title: "Авиабилеты", path: "/avia" },
  {
    title: "Отдых",
    links: [
      { label: "Поиск туров", path: "https://relax.probilets.com", target: "_blank", rel: "nofollow" },
      { label: "Отели", path: "https://hotels.probilets.com/", target: "_blank", rel: "nofollow" },
      { label: "Санатории", path: "https://relax.probilets.com/sanatoriums", target: "_blank", rel: "nofollow" },
      { label: "Проживание", path: "https://relax.probilets.com/placements", target: "_blank", rel: "nofollow" },
      { label: "Экскурсии", path: "https://relax.probilets.com/excursions", target: "_blank", rel: "nofollow" }
    ]
  },
  {
    title: "О компании",
    links: [
      { label: "О нас", path: "/about" },
      { label: "Контакты", path: "/contacts" },
      { label: "Реквизиты", path: "/requisites" },
      { label: "Новости", path: "/news/newss" },
      { label: "Обратная связь", path: "/support" },
      { label: "Отзывы", path: "/#feedback" },
      { label: "Спецпредложения", path: "/news/special-offers" }
    ]
  },
  {
    title: "Информация",
    links: [
      { label: "Оплата и возврат билетов", path: "/payment-and-refund" },
      { label: "FAQ", path: "/faq" }
      // { label: "Тех. поддержка", path: "/online" },
    ]
  }
];
export const linksListMobile = [
  {
    title: "Автобусы",
    links: [
      { label: "Маршруты", path: "/routes" },
      { label: "Автобусы онлайн", path: "/online" }
    ]
  },
  {
    title: "Отдых",
    links: [
      { label: "Поиск туров", path: "https://relax.probilets.com", target: "_blank", rel: "nofollow" },
      { label: "Санатории", path: "https://relax.probilets.com/sanatoriums", target: "_blank", rel: "nofollow" },
      { label: "Проживание", path: "https://relax.probilets.com/placements", target: "_blank", rel: "nofollow" },
      { label: "Экскурсии", path: "https://relax.probilets.com/excursions", target: "_blank", rel: "nofollow" }
    ]
  },
  { title: "Отели", path: "https://hotels.probilets.com/", target: "_blank", rel: "nofollow" },
  { title: "Procars", path: "https://procars.pro/", target: "_blank", rel: "nofollow" },
  { title: "Авиабилеты", path: "/avia" },
  {
    title: "О компании",
    links: [
      { label: "О нас", path: "/about" },
      { label: "Контакты", path: "/contacts" },
      { label: "Реквизиты", path: "/requisites" },
      { label: "Новости", path: "/news/newss" },
      { label: "Обратная связь", path: "/support" },
      // { label: "Блог", path: "/online" },
      { label: "Отзывы", path: "/#feedback" },
      { label: "Спецпредложения", path: "/news/special-offers" }
    ]
  },
  {
    title: "Информация",
    links: [
      { label: "Оплата и возврат билетов", path: "/payment-and-refund" },
      { label: "FAQ", path: "/faq" }
      // { label: "Тех. поддержка", path: "/online" },
    ]
  }
];
