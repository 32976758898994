import styled from "@emotion/styled";

import { newColors as colors } from "@/styles/variables";
import { ExpandIcon as ExpandIconSvg } from "@/modals/MobileAppPopup/Icons";

interface ExpandProps {
  isExpand: boolean;
}

export const BottomModalSheet = styled.div<ExpandProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  max-height: ${(props) => (props.isExpand ? "700px" : "100px")};
  background-color: #f3f5f6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: max-height 0.4s linear;
`;

export const Backdrop = styled.div<ExpandProps>`
  z-index: 99;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition:
    opacity 0.1s linear,
    height 0.1s linear;
  height: ${(props) => (props.isExpand ? "100%" : "0")};
  opacity: ${(props) => (props.isExpand ? "1" : "0")};
`;

export const Header = styled.div`
  position: relative;
`;

export const Content = styled.div`
  position: relative;
`;

export const ExpandButton = styled.button`
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.button`
  background-color: #dddddd;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 50%;
`;

export const FirstPoint = styled.div<ExpandProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  transition:
    opacity 0.6s,
    max-height 0.6s;
  opacity: ${(props) => (props.isExpand ? "0" : "1")};
  max-height: ${(props) => (props.isExpand ? "0" : "75px")};
`;

export const SecondPoint = styled.div<ExpandProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  transition:
    opacity 0.6s,
    max-height 0.4s;
  opacity: ${(props) => (props.isExpand ? "1" : "0")};
  max-height: ${(props) => (props.isExpand ? "700px" : "0")};
`;

export const FirstPointLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const FirstPointText = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: ${colors.text};
  margin-bottom: 4px;
`;

export const ExpandIcon = styled(ExpandIconSvg)<ExpandProps>`
  transform: rotate(${(props) => (props.isExpand ? "180deg" : "0deg")});
  transition: transform 0.2s;
`;

export const SecondPointTitle = styled.h2`
  font-size: 28px;
  font-weight: bold;
  color: ${colors.text};
  margin-bottom: 10px;
`;

export const SecondPointText = styled.p`
  font-size: 14px;
  color: ${colors.gray};
  padding: 0 15px;
  text-align: center;
  max-width: 330px;
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 15px;
  width: 100%;
`;

export const ButtonText = styled.button`
  font-size: 16px;
  color: ${colors.text};
`;

export const ImageWrapper = styled.div`
  padding: 0 15px 20px;
  width: 95%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
