import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  phone: string;
  isTabletDevice: boolean;
  isPhoneDevice: boolean;
  clientId: string | null;
  discount: number;
  ridesCount: number;
  isModalOpen: boolean;
  ymClientID: string | null;
}
export interface UserGetPayload {
  phone: string;
  clientId: string | null;
  discount: number;
  ridesCount: number;
}

const initialState: InitialState = {
  phone: "",
  isTabletDevice: false,
  isPhoneDevice: false,
  clientId: null,
  discount: 0,
  ridesCount: 0,
  isModalOpen: false,
  ymClientID: null,

  email: "",
  phone: "",
  isEdit: false,
  featureTrips: {},
  lastTrips: {},
  message: "",
  clientCard: null
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userGet: (state, action: PayloadAction<UserGetPayload>) => {
      state.phone = action.payload.phone;
      state.clientId = action.payload.clientId;
      state.discount = action.payload.discount;
      state.ridesCount = action.payload.ridesCount;
    },
    isMobileCheck: (state) => {
      state.isTabletDevice = window.screen.availWidth <= 1024 && window.screen.availWidth >= 768;
      state.isPhoneDevice = window.screen.availWidth < 768;
    },
    setModalVisible: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
    setClientID: (state, action: PayloadAction<string>) => {
      state.ymClientID = action.payload;
    }
  }
});

export const { userGet, isMobileCheck, setModalVisible, setClientID } = userSlice.actions;
export default userSlice.reducer;
