import * as S from "./styled";

interface IProps {
  onClick?: () => void;
  title?: string;
  footerVisible?: boolean;
  children?: React.ReactNode;
}

const Footer = ({ onClick, title = " ", footerVisible = true, children }: IProps) => {
  if (!footerVisible) return null;
  return (
    <S.Footer>
      {children}
      {!children && <div onClick={onClick}>{title}</div>}
    </S.Footer>
  );
};

export default Footer;
