import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import { sendApiRequest } from "@/core/Api";
import { AppState } from "@/store/store";
import { currencyFormatter } from "@/utils/currencyFunctions";
import { PayloadCurrentCurrency } from "@/store/slices/currency";

interface GlobalStructure {
  id: number;
  name: string;
}

export type Document = GlobalStructure;
export type Nationality = GlobalStructure;

type City = {
  id: number;
  name: string;
  latin_name: string;
  place: string;
  city_iso_code: number | null;
  region_name: string;
};
export interface ICurrencyState {
  cities: City[];
  nationalities: Nationality[];
  documents: Document[];
  callbackModal: boolean;
}
interface CityResponse {
  data: City[];
}

const initialState: ICurrencyState = {
  cities: [],
  nationalities: [],
  documents: [],
  callbackModal: false
};

export const fetchCities = createAsyncThunk("globalData/fetchCities", async (_, thunkAPI) => {
  const response = await sendApiRequest("getCities", "post", {}, true);
  if (response.status === 400) {
    return thunkAPI.rejectWithValue(response as MyKnownError);
  }
  return response;
});
export const fetchNationality = createAsyncThunk("globalData/fetchNationality", async (_, thunkAPI) => {
  const response = await sendApiRequest("getNationality", "post", {}, true);
  if (response.status === 400) {
    return thunkAPI.rejectWithValue(response as MyKnownError);
  }
  return response;
});
export const fetchDocuments = createAsyncThunk("globalData/Documents", async (_, thunkAPI) => {
  const response = await sendApiRequest("getDocuments", "post", {}, true);
  if (response.status === 400) {
    return thunkAPI.rejectWithValue(response as MyKnownError);
  }
  return response;
});

const globalData = createSlice({
  name: "globalData",
  initialState,
  reducers: {
    setCallbackModalVisible: (state, action: PayloadAction<boolean>) => {
      state.callbackModal = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase<any, PayloadAction<CityResponse>>(fetchCities.fulfilled, (state, action) => {
      state.cities = action.payload.data;
    });
    builder.addCase(fetchNationality.fulfilled, (state, { payload }) => {
      state.nationalities = payload.data;
    });
    builder.addCase(fetchDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload.data;
    });

    builder.addCase<typeof HYDRATE, PayloadAction<AppState, typeof HYDRATE>>(HYDRATE, (state, { payload }) => ({
      ...state,
      ...payload.globalData
    }));
  }
});

export const { setCallbackModalVisible } = globalData.actions;

export default globalData.reducer;
