import moment from "moment/moment";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import { AppState } from "@/store/store";

export interface City {
  id: number;
  name: string;
  latin_name: string;
}

interface InitialState {
  cityTo: City | null;
  cityFrom: City | null;
  date: string;
  adult: number;
  childs: number;
}
export type SearchForm = InitialState;

const date = moment().format("YYYY-MM-DD");

const initialState: InitialState = {
  cityTo: null,
  cityFrom: null,
  date,
  adult: 1,
  childs: 0
};

const searchFormSlice = createSlice({
  name: "searchForm",
  initialState,
  reducers: {
    saveSearchForm(state, action) {
      state.cityFrom = action.payload.cityFrom;
      state.cityTo = action.payload.cityTo;
      state.date = action.payload.date;
      state.adult = action.payload.adult;
      state.childs = action.payload.childs;
    }
  },
  extraReducers(builder) {
    builder.addCase<typeof HYDRATE, PayloadAction<AppState, typeof HYDRATE>>(HYDRATE, (state, { payload }) => ({
      ...state
    }));
  }
});

export const { saveSearchForm } = searchFormSlice.actions;

export default searchFormSlice.reducer;
