import { type Theme } from "@chakra-ui/theme";

const accordionTheme = {
  baseStyle: {
    container: {
      borderColor: "transparent"
    }
  }
};
const qwe: Theme = {
  components: {
    Accordion: {
      baseStyle: {
        container: {
          borderColor: "transparent"
        }
      }
    }
  }
};

export default accordionTheme;
