export const colors = {
  white: "#ffffff",
  lternative100: "#f6ecfc",
  alternative200: "#e0c1f4",
  alternative500: "#ac73e6",
  alternative600: "#9736e8",
  alternative700: "#8312d1",
  buttonNeutral0: "#ffffff",
  buttonPrimary500: "#7b79ff",
  buttonPrimary600: "#4945ff",
  danger100: "#fcecea",
  danger200: "#f5c0b8",
  danger500: "#ee5e52",
  danger600: "#d02b20",
  danger700: "#b72b1a",
  neutral0: "#ffffff",
  neutral100: "#f6f6f9",
  neutral150: "#eaeaef",
  neutral200: "#dcdce4",
  neutral300: "#c0c0cf",
  neutral400: "#a5a5ba",
  neutral500: "#8e8ea9",
  neutral600: "#666687",
  neutral700: "#4a4a6a",
  neutral800: "#32324d",
  neutral900: "#212134",
  neutral1000: "#181826",
  primary100: "#f0f0ff",
  primary200: "#d9d8ff",
  primary300: "#c5c3fc",
  primary400: "#0173ff",
  primary500: "#7b79ff",
  primary600: "#0372FF",
  primary700: "#271fe0",
  secondary100: "#eaf5ff",
  secondary200: "#b8e1ff",
  secondary500: "#66b7f1",
  secondary600: "#0c75af",
  secondary700: "#006096",
  success100: "#eafbe7",
  success200: "#c6f0c2",
  success500: "#5cb176",
  success600: "#328048",
  success700: "#2f6846",
  warning100: "#fdf4dc",
  warning200: "#fae7b9",
  warning500: "#f29d41",
  warning600: "#d9822f",
  warning700: "#be5d01",
  link: "#0095ff",
  border: "#dddddd"
};

export const newColors = {
  gray: "#555555",
  border: "#dddddd",
  text: "#212134",
  white: "#ffffff",
  mainBlue: "#0372FF",
  secondaryBlue: "#8360FF",
  whiteTransparent: "rgba(255, 255, 255, 0.9)"
};

export const sizes = {
  xs: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "36px"
};

export const styles = {
  boxShadow: "box-shadow: 0 5px 15px rgba(145, 178, 201, 0.25);",
  borderRadius: "border-radius: 8px;",
  shadow: "0 0 8px 0 rgba(0, 0, 0, 0.08)",
  br: "8px"
};
