import React from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { Field, useField } from "formik";

import LabelTooltip from "@/components/FormComponents/LabelTooltip";
import CustomPhoneInput from "@/components/FormComponents/PhoneInput";
import CustomCodeInput from "@/components/FormComponents/CustomCodeInput";
import CustomTextArea from "@/components/FormComponents/CustomTextArea";

import CustomError from "./CustomError";
import CustomInput from "./CustomInput";

interface IProps {
  name: string;
  label: string;
  children?: React.ReactElement;
  as?: React.ReactElement;
  props?: any;
  tooltipContent?: string;
  styleOtpWrapper?: any;
  disabled?: boolean;
}
const InputField = ({ name, label, tooltipContent, children, as, ...props }: IProps) => {
  const [, meta] = useField(name);
  const component = (fieldName: string) => {
    if (children) return children;
    else if (fieldName.includes("phone")) return CustomPhoneInput;
    else if (fieldName.includes("code")) return CustomCodeInput;
    else if (fieldName.includes("textarea")) return CustomTextArea;
    else return CustomInput;
  };
  return (
    <FormControl isInvalid={!!meta.error && meta.touched}>
      {!!label && (
        <FormLabel style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {label}
          {!!tooltipContent && <LabelTooltip content={tooltipContent} />}
        </FormLabel>
      )}
      <Field as={as} name={name} component={component(name)} isInvalid={!!meta.error && meta.touched} {...props} />
      <CustomError name={name} />
    </FormControl>
  );
};

export default InputField;
