import { IconProps } from "@/icons/types";

interface QuestionIconProps extends Omit<IconProps, "size"> {
  sizeX?: string;
  sizeY?: string;
}
const QuestionIcon = ({ sizeX = "14px", sizeY = "20px", fill = "#212134" }: QuestionIconProps) => (
  <svg width={sizeX} height={sizeY} viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3591 0.631668C9.27599 0.156219 8.1259 0.000121036 6.95205 0.000121036C5.94171 -0.00422212 4.94425 0.107423 3.99987 0.480168C1.91676 1.3023 0.517493 2.7542 0.0236932 5.01034C-0.0821932 5.49422 0.180627 5.84193 0.682767 5.89762C1.16797 5.95153 1.65318 6.00952 2.13611 6.08208C2.86746 6.19194 3.35621 5.9316 3.65719 5.25202C3.67555 5.21069 3.69281 5.16885 3.71007 5.12701C3.73483 5.06696 3.75959 5.00694 3.78759 4.94851C4.13633 4.21886 4.63923 3.63867 5.37108 3.27921C6.05694 2.94223 6.78324 2.86482 7.53228 2.92767C8.22749 2.98592 8.86154 3.1995 9.40614 3.65808C10.2944 4.40587 10.5598 5.55247 10.0778 6.61271C9.87772 7.05304 9.52709 7.365 9.17815 7.67545C9.16004 7.69156 9.14194 7.70767 9.12386 7.72379C8.83421 7.982 8.54219 8.23759 8.25019 8.49317C7.71036 8.96565 7.17056 9.43812 6.64576 9.92705C5.57881 10.9209 5.14971 12.199 5.13707 13.6379C5.13101 14.3372 5.65993 14.8703 6.35388 14.8816C6.69504 14.8869 7.03646 14.8898 7.37736 14.88C7.81355 14.8675 8.17619 14.68 8.37659 14.2896C8.50269 14.0441 8.56713 13.7539 8.60352 13.4762C8.66721 12.9903 8.81226 12.545 9.16 12.2001C9.66011 11.7042 10.1708 11.2182 10.6955 10.7492C10.7781 10.6754 10.8608 10.6018 10.9436 10.5282C11.6926 9.86206 12.4416 9.19603 13.0487 8.3883C14.0457 7.06133 14.2549 5.58543 13.6964 4.03926C13.1063 2.40547 11.9163 1.31533 10.3591 0.631668ZM5.99174 19.9519C6.02815 19.9676 6.06457 19.9834 6.1005 20H6.10075H7.56901C7.58228 19.9931 7.59539 19.9857 7.6085 19.9783C7.63807 19.9616 7.66764 19.9449 7.69915 19.9338C8.17577 19.7665 8.50859 19.3482 8.52628 18.8419C8.54397 18.348 8.54625 17.8524 8.52628 17.3586C8.50101 16.7354 8.01656 16.2454 7.39919 16.2125C7.02062 16.192 6.63953 16.1923 6.26122 16.2127C5.63096 16.247 5.14702 16.7651 5.13665 17.4015C5.12882 17.87 5.12604 18.3388 5.13792 18.8071C5.14929 19.2652 5.36738 19.6106 5.75833 19.8398C5.83255 19.8832 5.91212 19.9176 5.99174 19.9519Z"
      fill={fill}
    />
  </svg>
);

export default QuestionIcon;
