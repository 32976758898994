interface Props {
  active: boolean;
}

const RatingIcon = (props: Props) => {
  if (props.active) {
    return (
      <svg width="135" height="136" viewBox="0 0 135 136" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M67.5001 97.8633L90.8438 112.087C95.1188 114.693 100.35 110.84 99.2251 105.967L93.0376 79.22L113.681 61.2C117.45 57.9133 115.425 51.68 110.475 51.2833L83.3063 48.96L72.6751 23.6866C70.7626 19.0966 64.2376 19.0966 62.3251 23.6866L51.6938 48.9033L24.5251 51.2266C19.5751 51.6233 17.5501 57.8566 21.3188 61.1433L41.9626 79.1633L35.7751 105.91C34.6501 110.783 39.8813 114.637 44.1563 112.03L67.5001 97.8633Z"
          fill="#FFBF60"
        />
      </svg>
    );
  }
  return (
    <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.2726 108.013L88.5684 83.7858C87.7976 80.512 88.9166 77.0815 91.4696 74.8918L110.452 58.6108L85.3812 56.5042C82.0638 56.2255 79.1713 54.1383 77.8611 51.0779L67.9997 28.0433L58.1376 51.0278C56.8259 54.0847 53.9351 56.1689 50.6203 56.4474L25.5478 58.5541L44.5303 74.8351C47.0833 77.0248 48.2023 80.4553 47.4315 83.7292L41.7269 107.958L63.3875 95.0423C66.232 93.3461 69.7783 93.3492 72.6198 95.0504L94.2726 108.013ZM35.7121 107.324L41.5911 82.3541C41.8481 81.2628 41.4751 80.1193 40.6241 79.3894L20.9994 62.5576C17.1638 59.271 19.2248 53.0376 24.2626 52.641L50.118 50.4685C51.2229 50.3756 52.1865 49.6809 52.6237 48.6619L62.7331 25.1009C64.6796 20.5109 71.3203 20.5109 73.2667 25.1009L83.3769 48.7165C83.8136 49.7366 84.7778 50.4324 85.8836 50.5253L111.737 52.6976C116.775 53.0943 118.836 59.3276 115 62.6143L95.3758 79.4461C94.5248 80.176 94.1518 81.3195 94.4087 82.4108L100.288 107.381C101.433 112.254 96.1086 116.108 91.7578 113.501L69.5379 100.198C68.5907 99.6313 67.4086 99.6302 66.4604 100.196L44.2421 113.444C39.8912 116.051 34.5672 112.198 35.7121 107.324ZM68.257 27.4434C68.2572 27.4429 68.2571 27.443 68.2569 27.4436Z"
        fill="#8B8B8B"
      />
    </svg>
  );
};

export default RatingIcon;
