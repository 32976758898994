import _, { isString } from "lodash";
import { serialize } from "next-mdx-remote/serialize";

import type { Property } from "csstype";

import config from "../core/config";

import { BY, RU } from "./constants";

type Overflow = Property.Overflow;

export type DomainZone = "by" | "ru" | "kz" | "ua";
export type CurrencyAbbreviation = "BYN" | "RUB" | "KZT" | "UAH";

interface Localization {
  [key: string]: string;
}

export const formatDomainZone = (host: string | undefined): DomainZone | string => {
  if (!host && !isString(host)) return BY;

  const currentHost = !host.includes("localhost") ? host : BY;
  if (currentHost === BY) return currentHost;
  const parseDomain = currentHost?.split(".");

  return !parseDomain.includes(BY) && !parseDomain.includes(RU) ? BY : parseDomain[0];
};

export const setOverflow = (overflow: Overflow) => {
  const body = document.querySelector("body");
  if (body) {
    body.style.overflow = overflow;
  }
};

export const parseSearchString = (string: string | undefined) => {
  if (!isString(string)) return [];
  const routes = string.replace("bilety-na-avtobus-", "");
  return routes.split(/--|\//).filter((item) => !!item);
};

export const localization = (domainZone: DomainZone | string, isSeo = true) => {
  const locale: Localization = {
    by: "be-BY",
    ru: "ru-RU",
    kz: "kz-KZ",
    ua: "ua-UA"
  };
  const currentZone = locale[domainZone] ?? locale.by;
  return `${isSeo ? "&locale" : "?locale"}=${currentZone}`;
};

export const seoParam = (withImage = true, isFirst = true) => {
  const seoImage = withImage ? "seo.metaImage" : "";
  return `${
    isFirst ? "?populate=seo.metaSocial,seo.openGraph.images" : "&populate=seo.metaSocial,seo?.openGraph.images"
  },${seoImage}`;
};

export const formatterSingle = (data: any, param: any, isObject: boolean) => ({
  ...(data?.attributes?.Pages || data.attributes),
  id: data?.id,
  path: isObject ? param.path : param
});

export const formatterOGImage = (images: any) => ({
  ...images.data.map((image) => ({
    url: `${config.strapiCmsHost}${image.url}`,
    width: image.width,
    height: image.height,
    alt: image.alternativeText || ""
  }))
});

export const formatterCollection = (data: any, { title, path, description, keywords }: any) => {
  const content = data.map((item) => {
    const image = item.attributes?.seo?.metaImage?.data?.attributes || null;
    if (image) {
      const imageUrl = image.url;
      image.url = `${config.strapiCmsHost}${imageUrl}`;
    }
    return {
      ...item.attributes,
      id: item.id,
      parentTile: title,
      parentPath: path,
      image
    };
  });

  return {
    title,
    path,
    description,
    keywords,
    content
  };
};

export const formatterCollectionId = (data: any, path: string) => {
  const image = data?.attributes?.seo?.metaImage?.data?.attributes || null;
  if (image) {
    const imageUrl = image.url;
    image.url = `${config.strapiCmsHost}${imageUrl}`;
  }
  const returnedData = {
    ...data.attributes,
    id: data.id,
    parentPath: path,
    image
  };
  return returnedData;
};

export const formatterSeo = (data: any, param: string) => data.attributes[param];

export const replaceClassWithClassName = (htmlString: string) => htmlString.replace(/class=/g, "className=");

export const mdParser = async (mdString: string) => {
  try {
    return await serialize(mdString);
  } catch (e) {
    return await serialize(replaceClassWithClassName(mdString));
  }
};

export const debounceAsync = <A, R>(func: (arg: A) => Promise<R>, wait: number): ((arg: A) => Promise<R>) => {
  const debounced = _.debounce((resolve: (result: R) => void, reject: (reason?: any) => void, arg: A) => {
    func(arg).then(resolve).catch(reject);
  }, wait);

  return (arg: A): Promise<R> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, arg);
    });
};

export const IS_DEVELOP = process && process.env.NODE_ENV === "development";
// export const IS_DEVELOP = false;
