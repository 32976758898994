import { IconProps } from "@/icons/types";

const ViberIcon = ({ size = "24px", fill = "#fff" }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.715 6.202C8.52915 6.17495 8.33964 6.21236 8.178 6.308H8.164C7.789 6.528 7.451 6.805 7.163 7.131C6.923 7.408 6.793 7.688 6.759 7.958C6.739 8.118 6.753 8.28 6.8 8.433L6.818 8.443C7.088 9.236 7.44 9.999 7.87 10.717C8.42445 11.7255 9.10676 12.6582 9.9 13.492L9.924 13.526L9.962 13.554L9.985 13.581L10.013 13.605C10.8497 14.4006 11.7846 15.0862 12.795 15.645C13.95 16.274 14.651 16.571 15.072 16.695V16.701C15.195 16.739 15.307 16.756 15.42 16.756C15.7785 16.7295 16.1179 16.5838 16.384 16.342C16.709 16.054 16.984 15.715 17.198 15.338V15.331C17.399 14.951 17.331 14.593 17.041 14.35C16.4585 13.841 15.8286 13.389 15.16 13C14.712 12.757 14.257 12.904 14.073 13.15L13.68 13.646C13.478 13.892 13.112 13.858 13.112 13.858L13.102 13.864C10.371 13.167 9.642 10.402 9.642 10.402C9.642 10.402 9.608 10.026 9.861 9.834L10.353 9.438C10.589 9.246 10.753 8.792 10.5 8.344C10.1136 7.67476 9.66244 7.04508 9.153 6.464C9.04189 6.32725 8.88607 6.23411 8.713 6.201L8.715 6.202ZM13.33 5C13.1974 5 13.0702 5.05268 12.9764 5.14645C12.8827 5.24021 12.83 5.36739 12.83 5.5C12.83 5.63261 12.8827 5.75979 12.9764 5.85355C13.0702 5.94732 13.1974 6 13.33 6C14.594 6 15.644 6.413 16.475 7.205C16.902 7.638 17.235 8.151 17.453 8.713C17.672 9.276 17.772 9.877 17.746 10.479C17.7404 10.6116 17.7878 10.741 17.8776 10.8387C17.9674 10.9364 18.0924 10.9944 18.225 11C18.3576 11.0056 18.487 10.9582 18.5847 10.8684C18.6824 10.7786 18.7404 10.6536 18.746 10.521C18.777 9.78049 18.6541 9.04159 18.385 8.351C18.1147 7.65714 17.705 7.02612 17.181 6.497L17.171 6.487C16.14 5.502 14.836 5 13.33 5Z"
      fill={fill}
      fillOpacity="0.9"
    />
    <path
      d="M13.295 6.64407C13.1624 6.64407 13.0352 6.69674 12.9414 6.79051C12.8477 6.88428 12.795 7.01146 12.795 7.14407C12.795 7.27667 12.8477 7.40385 12.9414 7.49762C13.0352 7.59139 13.1624 7.64407 13.295 7.64407H13.312C14.224 7.70907 14.888 8.01307 15.353 8.51207C15.83 9.02607 16.077 9.66507 16.058 10.4551C16.0549 10.5877 16.1047 10.7161 16.1963 10.812C16.2879 10.9079 16.4139 10.9635 16.5465 10.9666C16.6791 10.9696 16.8075 10.9199 16.9034 10.8283C16.9993 10.7366 17.0549 10.6107 17.058 10.4781C17.082 9.44107 16.748 8.54607 16.086 7.83207V7.83007C15.409 7.10407 14.48 6.72007 13.362 6.64507L13.345 6.64307L13.295 6.64407Z"
      fill={fill}
      fillOpacity="0.9"
    />
    <path
      d="M13.276 8.31883C13.2091 8.31293 13.1417 8.32056 13.0778 8.34129C13.0139 8.36202 12.9548 8.39541 12.9041 8.43947C12.8534 8.48353 12.8121 8.53735 12.7826 8.59773C12.7532 8.6581 12.7362 8.72379 12.7327 8.79088C12.7292 8.85796 12.7393 8.92506 12.7623 8.98817C12.7853 9.05128 12.8208 9.10911 12.8667 9.1582C12.9125 9.20729 12.9678 9.24663 13.0292 9.27389C13.0906 9.30114 13.1568 9.31575 13.224 9.31683C13.642 9.33883 13.909 9.46483 14.077 9.63383C14.246 9.80383 14.372 10.0768 14.395 10.5038C14.3963 10.5709 14.411 10.6371 14.4384 10.6984C14.4657 10.7597 14.5051 10.8148 14.5543 10.8605C14.6034 10.9062 14.6612 10.9416 14.7243 10.9645C14.7874 10.9874 14.8544 10.9974 14.9214 10.9938C14.9884 10.9903 15.0541 10.9733 15.1144 10.9438C15.1747 10.9143 15.2284 10.8731 15.2724 10.8224C15.3164 10.7717 15.3498 10.7127 15.3705 10.6489C15.3912 10.585 15.3989 10.5177 15.393 10.4508C15.361 9.85083 15.173 9.32083 14.788 8.93083C14.401 8.54083 13.874 8.35083 13.276 8.31883Z"
      fill={fill}
      fillOpacity="0.9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.81699 2.38406C10.9991 1.67276 14.2989 1.67276 17.481 2.38406L17.82 2.45906C18.7502 2.66701 19.6045 3.12879 20.2881 3.79308C20.9716 4.45738 21.4576 5.29818 21.692 6.22206C22.4997 9.40495 22.4997 12.7392 21.692 15.9221C21.4576 16.8459 20.9716 17.6867 20.2881 18.351C19.6045 19.0153 18.7502 19.4771 17.82 19.6851L17.48 19.7601C15.487 20.2057 13.442 20.3737 11.403 20.2591L8.74999 22.6331C8.64997 22.7226 8.52775 22.7837 8.39608 22.8099C8.26441 22.8361 8.12812 22.8265 8.00144 22.782C7.87476 22.7376 7.76233 22.6599 7.67588 22.5572C7.58944 22.4545 7.53215 22.3305 7.50999 22.1981L7.07099 19.5761C6.22918 19.3141 5.4686 18.8407 4.86196 18.2009C4.25532 17.5612 3.82289 16.7766 3.60599 15.9221C2.79825 12.7392 2.79825 9.40494 3.60599 6.22206C3.84041 5.29818 4.32639 4.45738 5.00993 3.79308C5.69346 3.12879 6.5478 2.66701 7.47799 2.45906L7.81699 2.38406ZM17.154 3.84706C14.1873 3.18386 11.1107 3.18386 8.14399 3.84706L7.80399 3.92306C7.14471 4.07071 6.53926 4.39821 6.05487 4.86919C5.57048 5.34016 5.2261 5.93618 5.05999 6.59106C4.31371 9.53178 4.31371 12.6123 5.05999 15.5531C5.22618 16.2081 5.5707 16.8042 6.05528 17.2752C6.53986 17.7461 7.14553 18.0736 7.80499 18.2211L7.89499 18.2411C8.04059 18.2736 8.17316 18.3489 8.27577 18.4572C8.37837 18.5655 8.44634 18.7019 8.47099 18.8491L8.76499 20.6071L10.637 18.9321C10.7117 18.865 10.7992 18.8136 10.8942 18.7809C10.9892 18.7483 11.0898 18.7351 11.19 18.7421C13.1891 18.8839 15.1981 18.734 17.154 18.2971L17.493 18.2211C18.1525 18.0736 18.7581 17.7461 19.2427 17.2752C19.7273 16.8042 20.0718 16.2081 20.238 15.5531C20.984 12.6131 20.984 9.53206 20.238 6.59106C20.0718 5.93606 19.7273 5.33995 19.2427 4.86896C18.7581 4.39798 18.1525 4.07055 17.493 3.92306L17.154 3.84706Z"
      fill={fill}
      fillOpacity="0.9"
    />
  </svg>
);
export default ViberIcon;
