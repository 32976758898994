import styled from "@emotion/styled";
import { FormLabel as DefaultFormLabel } from "@chakra-ui/react";
import { Radio as DefaultRadio } from "@chakra-ui/radio";

import { colors as oldColors, newColors as colors } from "@/styles/variables";

export const LabelTooltip = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${oldColors.primary200};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormLabel = styled(DefaultFormLabel)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Radio = styled(DefaultRadio)`
  position: relative;
  &:before {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
