import { AbbreviationType, ICurrencyList } from "@/store/slices/currency";

import { RU, RUB, KZ, KZT, UA, UAH, BYN } from "./constants";

import { DomainZone, CurrencyAbbreviation } from ".";

interface IFormattedValue {
  currencyRate: number;
  currencyAbbreviation: AbbreviationType;
}
export const currencyFormatter = (currencyList: ICurrencyList, abbreviation: AbbreviationType): IFormattedValue => {
  if (abbreviation === "BYN") {
    return { currencyRate: 0, currencyAbbreviation: "BYN" };
  }

  if (Array.isArray(currencyList) && currencyList.length) {
    const { Cur_Scale, Cur_OfficialRate, Cur_Abbreviation } = currencyList.find(
      (item) => item.Cur_Abbreviation === abbreviation
    )!;

    const currencyOne = Cur_Scale / Cur_OfficialRate;

    return <IFormattedValue>{
      currencyRate: currencyOne,
      currencyAbbreviation: Cur_Abbreviation
    };
  }

  return { currencyRate: 0, currencyAbbreviation: "BYN" };
};

export const currencyAbbreviation = (domainZone: DomainZone | string): CurrencyAbbreviation => {
  switch (domainZone) {
    case RU:
      return RUB;
    case KZ:
      return KZT;
    case UA:
      return UAH;
    default:
      return BYN;
  }
};

export const totalPrice = (price: number | string, rate: number | string) => {
  const currencyPrice = Number(price);
  const currencyRate = Number(rate);
  return currencyRate ? (currencyPrice * currencyRate).toFixed(2) : currencyPrice.toFixed(2);
};
