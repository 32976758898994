import type { SVGProps } from "react";

const ClockIcon = (props: SVGProps<SVGSVGElement> & { pathProps?: SVGProps<SVGPathElement> }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3 17.7314V20.7714C3 21.0514 3.22 21.2714 3.5 21.2714H6.54C6.67 21.2714 6.8 21.2214 6.89 21.1214L17.81 10.2114L14.06 6.46136L3.15 17.3714C3.05 17.4714 3 17.5914 3 17.7314ZM20.71 7.31137C20.8027 7.21885 20.8762 7.10896 20.9264 6.98799C20.9766 6.86702 21.0024 6.73733 21.0024 6.60636C21.0024 6.4754 20.9766 6.34571 20.9264 6.22474C20.8762 6.10377 20.8027 5.99388 20.71 5.90137L18.37 3.56136C18.2775 3.46866 18.1676 3.39511 18.0466 3.34493C17.9257 3.29475 17.796 3.26892 17.665 3.26892C17.534 3.26892 17.4043 3.29475 17.2834 3.34493C17.1624 3.39511 17.0525 3.46866 16.96 3.56136L15.13 5.39136L18.88 9.14137L20.71 7.31137Z"
        fill="#868686"
        {...props.pathProps}
      />
    </svg>
  );
};

export default ClockIcon;
