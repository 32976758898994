export default {
  apiBasePath: "https://app.probilets.com/",
  // apiBasePath: "http://localhost:3000/",
  apiBasePathLocal: "http://localhost:3000/",
  apiKey: "d0d83e0b09ddecb21fecaf6821cf3d30",
  yandexApi: "https://payment.yandex.net/api/v3",
  shopId: "622634",
  redirect: "https://probilets.com/confirm",
  webpay: "https://payment.webpay.by",
  paymentTest: 0,
  storeId: "354925258",
  apiNbrb: "https://api.nbrb.by/exrates/rates?periodicity=0",
  apiIpWhoIs: "https://ipwhois.pro",
  tokenIpWhoIs: "nyUTq6VxJoAFnFqF",
  byDomain: "by.probilets.com",
  ruDomain: "ru.probilets.com",
  yandexAccount: [52030378],
  yandexMetric: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: "dataLayer"
  },
  // strapiCmsHost: "cms.belexpress.by",
  strapiCmsHost: "https://cms.probilets.com",
  strapiApiKey:
    "85a1cb8625d98da4de7a1d25440f6033a70fa1f536d4ed2d70246e3a8efdf808787e3b83b05b13a8102a71404d4937798fb358656b9708c3d1a4279bed98fae9fa837173f51da5b9ba7ccf9e215a4bf81db0ff4197d8f1e9e208d3def1e02e8057f3d4b9b1e299d97c9cfa6af87eedbf6e4b3698801529161c8d19b1282c6b8b",
  // strapiApiKey: "31a88b64c148ea085c8c0ced41ef0d7d9b018e85a9184fb877c9d86b2b74d62afbc4e254497e60d4cc8dc78d2236e79bb02d9cca04fb072e376e7b42c6ab402036e5484b1634b95be716635a8ecb618f556aa60d509e4e82ffb61276766a646834c72f43817c2ce78687863f53ef9a2fce2539457c42f7210c803388cf752082",
  yooKassaApi: "api.yookassa.ru/v3/payments",
  yooKassaApiKey: "live_MNoS9NJ0da9di5gYv8al9_7AMKf0KZmtyiee4zZ1T5o",
  yooKassaApiID: "609115"
};
