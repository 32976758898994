import { IconProps } from "@/icons/types";

const Ukraine = ({ size = "16px", ...props }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.5 2.5H2.5C0.84325 2.5 0 3.73125 0 5.25V8H16V5.25C16 3.73125 15.1567 2.5 13.5 2.5Z" fill="#1B75BB" />
    <path
      d="M0 10.75C0 12.2688 0.84325 13.5 2.5 13.5H13.5C15.1567 13.5 16 12.2688 16 10.75V8H0V10.75Z"
      fill="#F9CB38"
    />
  </svg>
);

export default Ukraine;
