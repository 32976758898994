import axios from "axios";

import { KS_SESSION_TOKEN } from "@/utils/constants";

export type IHttpMethod = "get" | "GET" | "post" | "POST" | "put" | "PUT" | "delete" | "DELETE";

const getKSToken = async (isAnonymous: boolean): Promise<string | null> => {
  let ksToken: string | null = null;

  if (isAnonymous) {
    return null;
  }

  if (localStorage.getItem(KS_SESSION_TOKEN)) {
    ksToken = localStorage.getItem(KS_SESSION_TOKEN);
    return ksToken;
  }

  throw new Error("KS token not found");
};

const apiPath = process.env.NEXT_PUBLIC_API_BASE_PATH;
export const httpClient = axios.create({
  baseURL: `${apiPath}/api`
});

export default httpClient;
