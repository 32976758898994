import styled from "@emotion/styled";
import Image from "next/image";

import { ContentWrapperTag } from "../../styles/pages";

export const FooterContentWrapper = styled(ContentWrapperTag)`
  display: flex;
  width: 100%;
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;

  a {
    transition: color 0.3s;
    &:hover {
      color: #3d86f7;
    }
  }
`;

export const TopFooter = styled.div`
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px 0 40px;

  ${FooterContentWrapper} {
    gap: 40px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }
`;
export const BottomFooter = styled.div`
  padding: 15px 0;
  background: #282828;
  display: flex;

  ${FooterContentWrapper} {
    align-items: center;
  }
`;

export const CopyrightWrapper = styled.div`
  width: 100%;
`;
export const CopyrightText = styled.p`
  color: #ffffff;
  font-size: 11px;
`;
export const ImageWrapper = styled.div`
  width: 60%;
`;
export const BottomFooterImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  @media (max-width: 570px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }
  @media (max-width: 410px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, auto);
  }
`;
export const LinkGroup = styled.div``;
export const GroupTitle = styled.h3`
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 500;
  line-height: 1em;
  font-size: 18px;
  margin: 20px 0 10px;
`;
export const GroupLinks = styled.ul``;
export const ListItem = styled.li`
  margin-bottom: 7px;
  font-size: 14px;

  a {
    display: block;
    letter-spacing: 0.04em;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.2em;
  }
  @media (max-width: 410px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

export const MobileAppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-area: 1 / 1 / 2 / 2;
`;
export const AnotherContent = styled.div`
  width: 100%;
  display: flex;
  max-width: 600px;
  justify-content: space-between;
  @media (max-width: 670px) {
    flex-wrap: wrap;
  }
`;
export const StoreImage = styled(Image)`
  width: 149px;
`;
export const StoreLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  a {
    transition: transform 0.3s;
    &:hover {
      transform: scale(0.97);
    }
  }
`;

export const SocialWrapper = styled.div`
  grid-area: 2 / 1 / 3 / 2;
`;
export const SocialLinks = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
`;
export const ConnectionWrapper = styled.div`
  grid-area: 1 / 2 / 3 / 3;
`;
export const ConnectionLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Text = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.9);
`;
export const ConnectionLink = styled.a``;

export const PhoneWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  ${Text} {
    color: rgba(255, 255, 255, 0.9);
  }
`;
export const TimeWrapper = styled.div`
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
`;
export const PhoneIcon = styled.div``;
export const PhoneNumbers = styled.div``;
export const PaymentServices = styled.div``;
export const PaymentServicesImage = styled.img`
  width: 100%;
`;
