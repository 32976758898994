import { css, Global } from "@emotion/react";
import { Roboto } from "next/font/google";

import { colors as oldColors, newColors as colors } from "@/styles/variables";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  display: "swap",
  subsets: ["cyrillic", "latin"]
});
export const globalStyles = (
  <Global
    styles={css`
      :root {
        --swiper-pagination-bottom: 0;
        --swiper-pagination-bullet-size: 12px;
        --swiper-pagination-color: rgba(85, 85, 85, 0.6);
        --chakra-colors-blue-500: ${colors.mainBlue};
      }
      html,
      body {
        margin: 0;
        padding: 0;
        font-family: ${roboto.style.fontFamily};
        min-height: 100%;
        height: 100%;
      }
      #__next {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100%;
      }

      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      ul {
        list-style: none;
      }

      button {
        border: none;
        background: transparent;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: ${oldColors.link};
        }
      }

      /* DYNAMIC STYLE CSS */

      .slick-initialized .slick-slide {
        padding: 0 5px;
      }

      .raw-html-embed {
        p {
          margin-bottom: 10px;
        }

        h2 {
          margin-bottom: 0.5rem;
          font-family: inherit;
          font-weight: 500;
          line-height: 1.1;
          color: inherit;
          font-size: 2rem;
        }

        .button-wrapper {
          display: flex;
          justify-content: flex-start;
        }

        .button-component-minimal {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          padding: 0 20px;
          transition: opacity 0.3s;
          border: 2px solid ${colors.mainBlue};
          border-radius: 8px;
          background-color: transparent;
          color: ${colors.mainBlue};
          font-family: Inter, sans-serif;
          font-size: 14px;
          text-decoration: none;
          font-weight: bold;
          text-transform: uppercase;

          &:hover {
            opacity: 0.7;
          }
        }
      }
      .swiper {
        padding-bottom: 30px;
      }
      .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .swiper-pagination-bullet-active {
        width: 16px;
        height: 16px;
      }
      .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
        position: relative;
        &:after {
          border-radius: 50%;
          position: absolute;
          content: "";
          display: block;
          width: 4px;
          height: 4px;
          background: white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .swiper-slide {
        height: initial;
      }

      .chakra-breadcrumb__link {
        text-wrap: nowrap;
      }
      .chakra-breadcrumb__list {
        overflow-x: auto;
      }
      .chakra-input__right-element {
        width: 40px !important;
        height: 40px !important;
      }
    `}
  />
);
