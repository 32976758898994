import type { SVGProps } from "react";

const ClockIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_45_4639)">
        <path
          d="M12.75 2C18.273 2 22.75 6.477 22.75 12C22.75 17.523 18.273 22 12.75 22C7.227 22 2.75 17.523 2.75 12C2.75 6.477 7.227 2 12.75 2ZM12.75 6C12.4848 6 12.2304 6.10536 12.0429 6.29289C11.8554 6.48043 11.75 6.73478 11.75 7V12C11.7501 12.2652 11.8555 12.5195 12.043 12.707L15.043 15.707C15.2316 15.8892 15.4842 15.99 15.7464 15.9877C16.0086 15.9854 16.2594 15.8802 16.4448 15.6948C16.6302 15.5094 16.7354 15.2586 16.7377 14.9964C16.74 14.7342 16.6392 14.4816 16.457 14.293L13.75 11.586V7C13.75 6.73478 13.6446 6.48043 13.4571 6.29289C13.2696 6.10536 13.0152 6 12.75 6Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_45_4639">
          <rect width="24" height="24" fill="white" transform="translate(0.75)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClockIcon;
