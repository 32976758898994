import React from "react";

import { IS_DEVELOP } from "@/utils";
import { BY, RU } from "@/utils/constants";

interface IProps {
  domain: string;
  renderBY?: React.ReactNode;
  renderRU?: React.ReactNode;
  children?: React.ReactNode;
}
const SpecialTagWrapper = ({ domain, renderBY, renderRU, children }: IProps) => {
  if (IS_DEVELOP) return;

  return (
    <>
      {domain === BY && <>{renderBY}</>}
      {domain === RU && <>{renderRU}</>}
      {children}
    </>
  );
};

export default SpecialTagWrapper;
