import styled from "@emotion/styled";
import PhoneInput from "react-phone-input-2";
import { Form as FormikForm } from "formik";

import { ContentWrapperCss, ContentWrapperTag } from "@/styles/pages";
import { newColors as colors, colors as oldColors } from "@/styles/variables";

export { TopWrapper, ContentWrapper, BackgroundImage } from "@/styles/index.styled";

export const StepperContentWrapper = styled.section`
  ${ContentWrapperCss};
  padding-top: 50px;
  margin-bottom: 30px;
  @media (max-width: 400px) {
    margin-top: 30px;
  }
`;
export const StepperWrapper = styled(ContentWrapperTag)`
  margin-top: 60px;
  margin-bottom: 40px;
`;

export const TicketSection = styled.section`
  ${ContentWrapperCss};
  margin-bottom: 50px;
`;

export const StyledPhoneInput = styled(PhoneInput)`
  background: #ffffff;
  max-width: initial;
  display: flex;
  height: 40px;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
`;

export const TicketsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const Total = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const ValidationWarning = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: ${oldColors.warning700};
  box-shadow: rgba(145, 178, 201, 0.65) 0 5px 30px 0;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: ${oldColors.warning100};
`;

export const TrackInfoWrapper = styled.section`
  margin-bottom: 30px;
`;

export const Price = styled.span`
  display: block;
  font-size: 28px;
  font-weight: bold;
  color: ${colors.mainBlue};
`;

export const Text = styled.span`
  font-size: 28px;
  color: ${colors.gray};
`;
export const TotalPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Form = styled(FormikForm)`
  @media (max-width: 1050px) {
    &:first-child {
      grid-area: 2 / 1 / 3 / 2;
    }
    &:last-child {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
`;
export const ButtonWrapper = styled.div`
  max-width: 280px;
  @media (max-width: 500px) {
    max-width: initial;
  }
`;
