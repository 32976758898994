import EmailIcon from "./EmailIcon";
import InfoIcon from "./InfoIcon";
import ScheduleIcon from "./ScheduleIcon";
import SkypeIcon from "./SkypeIcon";
import LocationIcon from "./LocationIcon";
import PhoneIcon from "./PhoneIcon";

export default {
  Email: EmailIcon,
  Info: InfoIcon,
  Schedule: ScheduleIcon,
  Skype: SkypeIcon,
  Location: LocationIcon,
  Phone: PhoneIcon
};
