import React, { CSSProperties, forwardRef } from "react";
import DatePicker, { type ReactDatePickerCustomHeaderProps, type ReactDatePickerProps } from "react-datepicker";
import { type InputProps, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import styled from "@emotion/styled";
import MaskedInput from "react-text-mask";
import moment from "moment";
import { capitalize, range } from "lodash";
import { getMonth, getYear } from "date-fns";
import { type ChakraStylesConfig, Select } from "chakra-react-select";

import { colors } from "@/styles/variables";

const years = range(1930, moment().year() + 1, 1).map((item) => ({ label: item, value: item }));
const months = moment.months().map((item, index) => ({ label: capitalize(item), value: item, index: index + 1 }));

const chakraStyles = (isYear: boolean): ChakraStylesConfig => ({
  menuList: (provided) => ({
    ...provided,
    padding: "5px",
    width: isYear ? "90px" : "125px"
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "13px",
    backgroundColor: state.isSelected ? "#0073ff" : provided.bg,
    borderRadius: "6px",
    color: state.isSelected ? "#ffffff" : colors.neutral900
  })
});

const LeftArrow = styled.span`
  left: initial;
  top: initial;
  right: initial;
  bottom: initial;
  &:before {
    transform: translateY(-100%) rotate(225deg);
  }
`;
const RightArrow = styled(LeftArrow)`
  &:before {
    transform: translateY(-100%) rotate(45deg);
  }
`;

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}: ReactDatePickerCustomHeaderProps) => {
  const filterableYears = years.find((item) => Number(item.value) === Number(getYear(date)));
  const filterableMonths = months.find((item) => item.index === getMonth(date) + 1);

  return (
    <div
      style={{
        margin: 10,
        display: "flex",
        justifyContent: "space-between",
        padding: "0 10px"
      }}>
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "28px",
          height: "28px"
        }}>
        <LeftArrow className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous" />
      </button>

      <div style={{ display: "flex", paddingLeft: "20px" }}>
        <Select
          options={months}
          size="md"
          placeholder="Выберите..."
          variant="unstyled"
          isSearchable={false}
          useBasicStyles
          chakraStyles={chakraStyles(false)}
          defaultValue={filterableMonths}
          value={filterableMonths}
          onChange={(option) => changeMonth(months.indexOf(option))}
        />
        <Select
          options={years}
          size="md"
          placeholder="Выберите..."
          variant="unstyled"
          isSearchable={false}
          useBasicStyles
          chakraStyles={chakraStyles(true)}
          defaultValue={filterableYears}
          value={filterableYears}
          onChange={(option) => changeYear(option.value)}
        />
      </div>

      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "28px",
          height: "28px"
        }}>
        <RightArrow className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next" />
      </button>
    </div>
  );
};

const DatePickerInput = forwardRef((props: any, ref) => {
  return (
    <InputGroup size="md">
      <InputRightElement pointerEvents="none">
        <CalendarIcon color="#808999" w="18px" h="18px" />
      </InputRightElement>

      <MaskedInput
        mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
        placeholder="Enter a phone number"
        id="my-input-id"
        ref={ref}
        {...props}
        render={(innerRef, innerProps) => (
          <Input variant="outline" {...props.inputProps} ref={innerRef} {...innerProps} />
        )}
      />
    </InputGroup>
  );
});

const DatePickerWrapper = styled.div`
  width: 100%;
  max-width: 205px;

  @media (max-width: 767px) {
    max-width: 470px;
  }
`;
const CustomDatePicker = (
  props: ReactDatePickerProps & {
    isPure?: boolean;
    inputProps: InputProps;
    wrapperStyle?: CSSProperties;
  }
) => {
  if (props.isPure) {
    return (
      <DatePicker
        portalId="modal"
        renderCustomHeader={CustomHeader}
        calendarStartDay={1}
        customInput={<DatePickerInput inputProps={props.inputProps} />}
        dateFormat="dd-MM-yyyy"
        {...props}
      />
    );
  }
  return (
    <DatePickerWrapper style={props.wrapperStyle}>
      <DatePicker
        portalId="modal"
        calendarStartDay={1}
        customInput={<DatePickerInput />}
        dateFormat="dd-MM-yyyy"
        {...props}
      />
    </DatePickerWrapper>
  );
};

export default CustomDatePicker;
