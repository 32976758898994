import { IconProps } from "@/icons/types";

const WhatsappIcon = ({ size = "24px", fill = "#fff" }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.75 4.91006C18.8332 3.98399 17.7412 3.24973 16.5376 2.75011C15.3341 2.25049 14.0431 1.99552 12.74 2.00006C7.28 2.00006 2.83 6.45006 2.83 11.9101C2.83 13.6601 3.29 15.3601 4.15 16.8601L2.75 22.0001L8 20.6201C9.45 21.4101 11.08 21.8301 12.74 21.8301C18.2 21.8301 22.65 17.3801 22.65 11.9201C22.65 9.27006 21.62 6.78006 19.75 4.91006ZM12.74 20.1501C11.26 20.1501 9.81 19.7501 8.54 19.0001L8.24 18.8201L5.12 19.6401L5.95 16.6001L5.75 16.2901C4.92775 14.977 4.49114 13.4593 4.49 11.9101C4.49 7.37006 8.19 3.67006 12.73 3.67006C14.93 3.67006 17 4.53006 18.55 6.09006C19.3175 6.85402 19.9257 7.76272 20.3394 8.76348C20.7531 9.76425 20.964 10.8372 20.96 11.9201C20.98 16.4601 17.28 20.1501 12.74 20.1501ZM17.26 13.9901C17.01 13.8701 15.79 13.2701 15.57 13.1801C15.34 13.1001 15.18 13.0601 15.01 13.3001C14.84 13.5501 14.37 14.1101 14.23 14.2701C14.09 14.4401 13.94 14.4601 13.69 14.3301C13.44 14.2101 12.64 13.9401 11.7 13.1001C10.96 12.4401 10.47 11.6301 10.32 11.3801C10.18 11.1301 10.3 11.0001 10.43 10.8701C10.54 10.7601 10.68 10.5801 10.8 10.4401C10.92 10.3001 10.97 10.1901 11.05 10.0301C11.13 9.86006 11.09 9.72006 11.03 9.60006C10.97 9.48006 10.47 8.26006 10.27 7.76006C10.07 7.28006 9.86 7.34006 9.71 7.33006H9.23C9.06 7.33006 8.8 7.39006 8.57 7.64006C8.35 7.89006 7.71 8.49006 7.71 9.71006C7.71 10.9301 8.6 12.1101 8.72 12.2701C8.84 12.4401 10.47 14.9401 12.95 16.0101C13.54 16.2701 14 16.4201 14.36 16.5301C14.95 16.7201 15.49 16.6901 15.92 16.6301C16.4 16.5601 17.39 16.0301 17.59 15.4501C17.8 14.8701 17.8 14.3801 17.73 14.2701C17.66 14.1601 17.51 14.1101 17.26 13.9901Z"
      fill={fill}
      fillOpacity="0.9"
    />
  </svg>
);

export default WhatsappIcon;
