import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { setOverflow } from "@/utils";

import { ANIMATION_TIME } from "../constants";

import * as S from "./styled";
import A from "./animation.module.css";

interface IProps {
  onClose: () => void;
  children: React.ReactNode;
  opened: boolean;
}

const overlayAnimation = {
  enter: A.overlayEnter,
  enterActive: A.overlayEnterActive,
  exit: A.overlayExit,
  exitActive: A.overlayExitActive
};

const contentAnimation = {
  enter: A.contentEnter,
  enterActive: A.contentEnterActive,
  exit: A.contentExit,
  exitActive: A.contentExitActive
};

const Layout = ({ onClose, children, opened }: IProps) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [animationIn, setAnimationIn] = useState(false);

  useEffect(() => {
    setAnimationIn(opened);
    if (opened) {
      setOverflow("hidden");
    } else {
      setOverflow("auto");
    }
  }, [opened]);

  return (
    <S.Container>
      <CSSTransition
        in={animationIn}
        nodeRef={overlayRef}
        timeout={ANIMATION_TIME}
        mountOnEnter
        unmountOnExit
        classNames={overlayAnimation}>
        <S.Overlay ref={overlayRef} onClick={onClose} />
      </CSSTransition>
      <CSSTransition
        in={animationIn}
        nodeRef={contentRef}
        timeout={ANIMATION_TIME}
        mountOnEnter
        unmountOnExit
        classNames={contentAnimation}>
        <S.Content ref={contentRef}>{children}</S.Content>
      </CSSTransition>
    </S.Container>
  );
};

export default Layout;
