import { extendTheme, type Theme } from "@chakra-ui/react";

import { newColors as colors } from "@/styles/variables";

import {
  stepperTheme,
  radioTheme,
  inputTheme,
  checkboxTheme,
  formLabelTheme,
  accordionTheme,
  tableTheme
} from "./theme";

// const qwe : Theme = {}

const themeConfig = extendTheme({
  fonts: {
    heading: "'Roboto', sans-serif",
    body: "'Roboto', sans-serif"
  },
  components: {
    Input: inputTheme,
    Stepper: stepperTheme,
    Checkbox: checkboxTheme,
    Radio: radioTheme,
    FormLabel: formLabelTheme,
    Accordion: accordionTheme,
    Table: tableTheme,
    Progress: {},
    Menu: {
      baseStyle: {
        item: {
          _hover: {
            bg: "#f3f5f6"
          }
        }
      }
    }
  }
});

export default themeConfig;
