import { type FieldProps } from "formik";
import React, { memo, useState } from "react";
import ru from "react-phone-input-2/lang/ru.json";

import * as S from "@/styles/checkout.styled";
import { colors } from "@/styles/variables";
import { BY } from "@/utils/constants";

const CustomPhoneInput = ({ field, isInvalid, ...props }: FieldProps & { isInvalid: boolean }) => {
  const [focusInput, setFocusInput] = useState(false);
  const [country] = useState(BY);

  const borderInput = isInvalid ? "2px solid #E53E3E" : "1px solid #dddddd";
  return (
    <S.StyledPhoneInput
      masks={{
        ru: "(...) ...-..-..",
        by: "(..) ... -..-.."
      }}
      {...props}
      containerStyle={{
        border: `${focusInput ? `2px solid ${colors.primary500}` : borderInput}`
      }}
      placeholder="+375 (__) ___-__-__"
      inputProps={{ name: "phone" }}
      onChange={(_, country, e) => {
        field.onChange(e);
      }}
      onBlur={(e) => {
        setFocusInput(false);
        field.onBlur(e);
      }}
      inputStyle={{
        height: "100%",
        padding: 0,
        paddingLeft: "50px",
        paddingRight: "10px",
        borderRadius: "7px"
      }}
      buttonStyle={{
        left: "15px"
      }}
      localization={ru}
      onFocus={() => setFocusInput(true)}
      value={field.value}
      onlyCountries={["ru", "by", "ua", "kz"]}
    />
  );
};

export default memo(CustomPhoneInput);
