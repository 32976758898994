import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ContentWrapperTag = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 30px;

  @media (max-width: 400px) {
    padding: 0 15px;
  }
`;
export const ContentWrapperCss = css`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 30px;

  @media (max-width: 400px) {
    padding: 0 15px;
  }
`;

export const ContainerWithColor = styled.section<{ isSmall: boolean }>`
  background: #000529;
  background: linear-gradient(to right, #000529 1%, #002055 24%, #005db5 67%, #0074d9 100%);
  margin-top: -65px;
  min-height: ${(props) => (props.isSmall ? "150px" : "300px")};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ContainerWithColorTitle = styled.h1`
  text-transform: uppercase;
  line-height: 1em;
  color: #ffffff;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.08em;
  font-weight: 700;
  font-size: 30px;
  margin-top: 50px;
`;
export const SubText = styled.h1`
  letter-spacing: 0.04em;
  line-height: 1em;
  font-size: 27px;
  margin-top: 30px;
  color: #ffffff;
`;
export const Section = styled.section`
  margin-top: 40px;
  margin-bottom: 60px;
`;

export const Grid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-column-gap: 20px;
  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    grid-row-gap: 20px;
    margin-bottom: 20px;
  }
`;
