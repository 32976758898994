import Icon from "@/icons";
import { newColors as colors } from "@/styles/variables";

import * as S from "./styled";
interface IProps {
  onClick: () => void;
  title: string;
}

const Header = ({ onClick, title }: IProps) => {
  return (
    <S.Header>
      <S.Title>{title}</S.Title>
      <S.CloseButton onClick={onClick}>
        <Icon.Close fill={colors.text} size={20} />
      </S.CloseButton>
    </S.Header>
  );
};

export default Header;
