import styled from "@emotion/styled";
import Image from "next/image";
import Link from "next/link";

import { ContentWrapperTag } from "@/styles/pages";
import { newColors as colors, colors as oldColors } from "@/styles/variables";
export const Header = styled.header`
  background: #ffffff;
  color: ${oldColors.neutral900};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${oldColors.neutral100};
  height: 65px;
  padding: 10px 0;
  z-index: 11;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  box-shadow: ${(props) => (props.isDark ? "none" : "black 0 0 5px -2px")};
  transition:
    background-color 0.3s,
    border-bottom-color 0.15s;
`;

export const HeaderWrapper = styled(ContentWrapperTag)`
  display: flex;
  justify-content: space-between;
`;
export const MainHeader = styled.div`
  width: 100%;
`;

export const Navigation = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1180px) {
    display: none;
  }
`;

export const PhoneWrapperMobile = styled.div`
  display: none;
  @media (max-width: 1180px) {
    display: flex;
  }
`;
export const LinkList = styled.ul`
  display: flex;
  margin-left: 100px;
  gap: 15px;

  a,
  p,
  span,
  li {
    color: inherit;
    transition: color 0.15s;
  }
  @media (max-width: 1310px) {
    margin-left: 40px;
  }
  @media (min-width: 1100px) {
    align-items: center;
  }
`;

export const AccountWrapper = styled.ul`
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 0;
  padding: 0;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const AccountButton = styled.button`
  color: inherit;
  display: flex;
  gap: 5px;
  align-items: center;
`;
export const SearchWrapper = styled.div``;
export const ButtonText = styled.button`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  outline: none;
`;
export const CurrencyWrapper = styled.div`
  margin-right: 40px;
  display: flex;
  color: inherit;
  gap: 5px;
  align-items: center;

  @media (max-width: 1310px) {
    margin-right: 7px;
    margin-left: 10px;
  }
`;

export const BurgerMenu = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media (max-width: 1180px) {
    display: flex;
  }
`;

export const BurgerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 30px;

  ${Navigation} {
    height: 100%;
    @media (max-width: 1180px) {
      display: flex;
    }
  }
  ${LinkList} {
    flex-direction: column;
    align-items: flex-start;
    font-size: 20px;
    margin-left: 0;
  }
  ${Navigation} {
    flex-direction: column;
  }
  ${CurrencyWrapper} {
    width: 135px;
    margin-right: 0;
    margin-top: 30px;
    color: #2c3e50;
  }

  ${AccountWrapper} {
    margin-bottom: 30px;
    flex-direction: row;
  }

  ${AccountButton} {
    color: #2c3e50;
    font-size: 20px;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;
export const UserName = styled.span``;
export const Button = styled.button``;

export const PhoneWrapper = styled.div`
  margin-right: 40px;
  display: flex;
  align-items: center;

  @media (max-width: 1310px) {
    margin-right: 7px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const BlogButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background-image: linear-gradient(
    135deg,
    rgba(3, 114, 255, 1) 0%,
    rgba(131, 96, 255, 1) 57%,
    rgba(185, 86, 229, 1) 100%
  );
  border-radius: 8px;
  gap: 8px;
  color: white !important;

  &:hover {
    background-image: linear-gradient(
      135deg,
      rgba(3, 114, 255, 1) 0%,
      rgba(131, 96, 255, 1) 45%,
      rgba(185, 86, 229, 1) 91%
    );
    color: #ffffff;
  }
`;

export const DropdownLinks = styled.button``;
export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 1180px) {
    display: block;
  }
`;

export const DesctopWrapper = styled.div`
  display: block;

  @media (max-width: 1100px) {
    display: none;
  }
`;
