import React, { useEffect, useState } from "react";
import cn from "classnames";

import { RU } from "@/utils/constants";

import s from "../styles.module.css";
import config from "../../core/config";

interface IProps {
  closeModal: () => void;
  country: string;
  code: string;
}
const LocationModal = ({ closeModal, country, code }: IProps) => {
  const [domain, setDomain] = useState(config.ruDomain);
  const existingDomain = "перейдите на домен соответствующий вашему региону";
  const notExistingDomain = "перейдите на наш основной домен";
  const [locationText, setLocationText] = useState(existingDomain);

  useEffect(() => {
    if (code !== RU) {
      setDomain(config.byDomain);
      setLocationText(notExistingDomain);
    }
  }, []);

  return (
    <div className={cn(s.modal, s.modalGeo)}>
      <div className={cn(s.modalWrapper, s.modalWrapperGeo)}>
        <button className={s.buttonClose} onClick={closeModal} aria-label="Закрыть модальное окно">
          <i className={`${s.icon} fas fa-times`}></i>
        </button>
        <h2 className={s.modalTitle}>Ваша страна определена как {country}.</h2>
        <p className={cn(s.modalDescription, s.modalDescriptionGeo)}>
          Для получения актуальной информации и цен, {locationText} - <a href={`https://${domain}`}>{domain}</a>
        </p>
        <div className={s.imageWrapper}>
          <img className={s.geoImage} src="/landing/geo-image.webp" alt="Картинка геолокации" />
        </div>
        <div className={s.buttonWrapper}>
          <a className={`${s.btn} ${s.btnSpecial} noIcon size2x`} href={`https://${domain}`}>
            Перейти
          </a>
          <button className={s.buttonDiz} onClick={closeModal} aria-label="Закрыть модальное окно">
            Остаться
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationModal;
