import styled from "@emotion/styled";
import Image from "next/image";

import { ContentWrapperCss, ContentWrapperTag } from "@/styles/pages";
import { newColors as colors, sizes, styles } from "@/styles/variables";
import { PopularTripCard } from "@/components/pages/main/CardRoute/styled";
import Button from "@/components/Buttons";

export const TopWrapper = styled.div<{ isMain?: boolean; isAvia?: boolean }>`
  position: relative;
  min-height: ${({ isMain }) => (isMain ? "450px" : "320px")};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1200px) {
    min-height: ${({ isAvia }) => (isAvia ? "550px" : "450px")};
  }
  @media (max-width: 770px) {
    min-height: ${(props) => (props.isMain ? "800px" : "335px")};
  }
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-position: center 37%;
`;

export const NewsImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  @media (max-width: 1200px) {
    padding-bottom: 33.25%;
  }
  @media (max-width: 870px) {
    padding-bottom: 37.25%;
  }
  @media (max-width: 800px) {
    padding-bottom: 56.25%;
  }
`;

export const PlaceholderImage = styled(Image)`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 100%;
  filter: blur(7px);
  z-index: 1;
`;

export const Main = styled.div``;

export const ContentWrapper = styled(ContentWrapperTag)`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  margin-top: 18px;
`;

export const InfoSection = styled.section`
  ${ContentWrapperCss};
  display: grid;
  gap: 40px;
  margin-bottom: 40px;

  grid-template-columns: 1.2fr 0.9fr 0.9fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "news news all-news"
    "feedback mobile-app mobile-app"
    "faq faq faq";

  .news {
    grid-area: news;
  }
  .all-news {
    grid-area: all-news;
  }
  .mobile-app {
    grid-area: mobile-app;
  }
  .faq {
    grid-area: faq;
  }

  @media (max-width: 1200px) {
    grid-template-rows: auto auto auto auto;
    gap: 25px;
    grid-template-areas:
      "news news news"
      "feedback feedback all-news"
      "mobile-app mobile-app mobile-app"
      "faq faq faq";

    .news {
      grid-area: news;
    }
    .feedback {
      grid-area: feedback;
    }
    .all-news {
      grid-area: all-news;
    }
    .mobile-app {
      grid-area: mobile-app;
    }
    .faq {
      grid-area: faq;
    }
  }

  @media (max-width: 710px) {
    grid-template-rows: auto auto auto auto auto;
    gap: 25px;
    grid-template-areas:
      "news news news"
      "feedback feedback feedback"
      "all-news all-news all-news"
      "mobile-app mobile-app mobile-app"
      "faq faq faq";

    .news {
      grid-area: news;
    }
    .feedback {
      grid-area: feedback;
    }
    .all-news {
      grid-area: all-news;
    }
    .mobile-app {
      grid-area: mobile-app;
    }
    .faq {
      grid-area: faq;
    }
  }
`;

export const BlockCategory = styled.span`
  position: absolute;
  left: 40px;
  top: 20px;
  color: ${colors.mainBlue};
  font-size: 16px;
  font-weight: bold;
  display: block;
  @media (max-width: 750px) {
    left: 25px;
  }
`;

export const MoreDetailed = styled.span`
  position: absolute;
  right: 45px;
  bottom: 20px;
  font-size: 16px;
  font-weight: normal;
  display: block;
`;

export const BlockTitle = styled.h2`
  color: ${colors.text};
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 25px;
`;

export const BlockText = styled.p`
  max-width: 600px;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BlockTextWrapper = styled.div`
  margin-bottom: 15px;
`;
export const BlockImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 300px;

  @media (max-width: 670px) {
    flex: initial;
  }
`;

export const BlockImage = styled(Image)`
  border-radius: 8px;
  @media (max-width: 670px) {
    margin-bottom: 15px;
  }
`;

export const BlockDate = styled.span`
  position: absolute;
  left: 40px;
  bottom: 20px;
  color: #808999;
  font-size: 14px;
  font-weight: bold;
  display: block;
`;

export const LastNews = styled.div`
  position: relative;
  background: #edf2fa;
  padding: 50px 40px 40px;
  min-width: 300px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  @media (max-width: 750px) {
    padding: 50px 25px 40px;
  }
`;
export const LastNewsItem = styled.span`
  font-size: 14px;
`;

export const AppBlock = styled.div`
  position: relative;
  background: #edf2fa;
  padding: 62px 50px;
  gap: 15px;
  width: 100%;
  height: 340px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 750px) {
    flex-direction: column;
    height: initial;
    padding: 20px 25px 62px;
    align-items: center;
  }
`;
export const AppBlockTitle = styled.h2`
  color: #2d2d2d;
  font-family: Direct, sans-serif;
  font-size: 34px;
  font-style: normal;
  line-height: 121.846%;
  margin-bottom: 40px;
  max-width: 365px;
  font-weight: 400;

  @media (max-width: 420px) {
    font-size: 28px;
  }
`;
export const AppTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const AppImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;

  @media (max-width: 750px) {
    bottom: -20px;
  }
`;

export const AppBlockImage = styled(Image)`
  width: 170px;
  height: auto;

  @media (max-width: 420px) {
    width: auto;
    height: 65px;
  }
`;

export const TrackSection = styled.section`
  ${ContentWrapperCss};
  margin-top: 70px;
`;
export const TrackTitle = styled.h3`
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TrackList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

  &:last-child {
    margin-top: 40px;
  }
`;

export const TrackRoute = styled.h4`
  display: block;
  position: relative;
  font-weight: bold;

  //&:after {
  //  content: "";
  //  position: absolute;
  //  bottom: -3px;
  //  left: 0;
  //  width: 100%;
  //  height: 1px;
  //  background-color: blue;
  //}
`;
export const TrackPrice = styled.div``;
export const TrackItem = styled.div`
  width: 245px;
  height: 130px;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 30px 0 rgba(145, 178, 201, 0.65);
  box-shadow: 0 5px 30px 0 rgba(145, 178, 201, 0.65);

  &:hover {
    ${TrackRoute} {
      color: #6464ff;
    }
  }
`;

export const TrackItemLarge = styled(TrackItem)`
  width: 300px;
  height: 150px;
  ${TrackRoute} {
    font-size: 20px;
  }
`;

export const AboutBlockContainer = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 40px;
  @media (max-width: 700px) {
    gap: 28px;
  }
`;
export const AboutBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 310px;
  min-width: 310px;
  @media (max-width: 700px) {
    max-width: initial;
    flex-direction: row;
    gap: 16px;
  }
`;
export const AboutBlockTitle = styled.h3`
  font-size: ${sizes.lg};
  font-weight: bold;
  color: ${colors.text};
  margin-bottom: 5px;
`;
export const AboutBlockText = styled.p`
  font-size: 14px;
  line-height: 1.7;
`;
export const AboutBlockImage = styled.div`
  display: flex;
  margin-bottom: 15px;
  min-width: 64px;

  @media (max-width: 700px) {
    align-items: flex-start;
  }
`;
export const AboutButton = styled(Button)`
  @media (max-width: 700px) {
    max-width: initial !important;
  }
`;
export const AboutDescriptionContainer = styled.section`
  ${ContentWrapperCss};
  margin-top: 70px;
  margin-bottom: 60px;

  .line-after {
    margin-bottom: 1em;
  }

  .title {
    margin: 0 0 15px;
    color: #343435;
    font-family: Raleway, sans-serif;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 1em;
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
    margin-bottom: 35px;
  }
  .home-why-title {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    line-height: 27px;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-auto-rows: 1fr 1fr 1fr;
    }
  }
`;

export const SmallTrigger = styled.button<{ isOpen: boolean }>`
  background: none;
  border: none;
  margin-left: 15px;
  transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
`;

export const TitleWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const MainTitle = styled.h1`
  color: ${colors.white};
  margin: 0 0 15px;
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 1em;
  text-transform: uppercase;

  @media (max-width: 770px) {
    font-size: 28px;
    line-height: 1.3em;
  }
`;
export const MainSubTitle = styled.p`
  color: ${colors.white};
  font-size: 27px;
  letter-spacing: 0.04em;
  line-height: 1em;
  text-transform: uppercase;
  @media (max-width: 770px) {
    font-size: 24px;
  }
`;
export const SocialMediaSection = styled.section`
  ${ContentWrapperCss};
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

export const SocialMediaTitle = styled.span`
  margin-left: 2rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
`;
export const SocialMediaLinkContainer = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 345px;
`;

export const News = styled.div<{ small?: boolean }>`
  box-shadow: ${styles.shadow};
  background-color: #ffffff;

  &:nth-child(1) {
    display: flex;
    grid-area: 1 / 1 / 3 / 2;
    & p {
      -webkit-line-clamp: 4;
    }
    @media (min-width: 1200px) {
      flex-direction: column;
    }
  }

  &:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    & p {
      -webkit-line-clamp: 3;
    }
    & ${NewsImageContainer} {
      order: 1;
    }
  }
  &:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
    & p {
      -webkit-line-clamp: 3;
    }

    & ${NewsImageContainer} {
      order: 1;
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    min-height: 530px;

    & img {
      width: 100% !important;
      height: 230px !important;
    }
    & ${NewsImageContainer} {
      order: 0;
    }
  }
`;

export const NewsWrapper = styled.div`
  display: none;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;

  @media (min-width: 600px) {
    display: grid;
  }
  @media (min-width: 1200px) {
    ${News} {
      &:nth-child(2) {
        & ${NewsImageContainer} {
          padding-bottom: 47.25%;
        }
      }
      &:nth-child(3) {
        & ${NewsImageContainer} {
          padding-bottom: 47.25%;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(3, auto);
    ${News} {
      &:nth-child(1) {
        display: flex;
        grid-area: 1 / 1 / 2 / 2;
        & p {
          -webkit-line-clamp: 4;
        }
        & img {
          width: 327px !important;
          height: auto !important;
        }
        & ${NewsImageContainer} {
          order: 1;
        }
      }

      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
        display: flex;
        & p {
          -webkit-line-clamp: 3;
        }
        & ${NewsImageContainer} {
          order: 1;
        }
      }
      &:nth-child(3) {
        grid-area: 3 / 1 / 4 / 2;
        display: flex;
        & p {
          -webkit-line-clamp: 3;
        }

        & ${NewsImageContainer} {
          order: 1;
        }
      }
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(3, auto);
    ${News} {
      &:nth-child(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-area: 1 / 1 / 2 / 2;
        & p {
          -webkit-line-clamp: 4;
        }
        & img {
          width: 100% !important;
          height: auto !important;
        }
        ${NewsImageContainer} {
          order: 0;
        }
      }

      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
        display: flex;
        align-items: center;
        flex-direction: column;

        & p {
          -webkit-line-clamp: 3;
        }
        & img {
          width: 100% !important;
          height: auto !important;
        }
        ${NewsImageContainer} {
          order: 0;
        }
      }
      &:nth-child(3) {
        grid-area: 3 / 1 / 4 / 2;
        display: flex;
        align-items: center;
        flex-direction: column;

        & p {
          -webkit-line-clamp: 3;
        }

        & img {
          width: 100% !important;
          height: auto !important;
        }
        ${NewsImageContainer} {
          order: 0;
        }
      }
    }
  }
`;

export const NewsWrapperSlider = styled.div`
  width: 100%;
  display: none;
  @media (max-width: 599px) {
    display: block;
  }
`;
export const NewsContentWrapper = styled.div`
  padding: 20px 20px 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-width: 50%;
`;
export const NewsTitle = styled.h3`
  font-size: ${sizes.lg};
  font-weight: 500;
  margin-bottom: 10px;
`;
export const NewsText = styled.p`
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const NewsDate = styled.span`
  display: block;
  color: ${colors.gray};
  font-size: ${sizes.sm};
  margin-bottom: 12px;
`;

export const MobileAppSection = styled(ContentWrapperTag)`
  position: relative;
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  @media (max-width: 740px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  @media (max-width: 400px) {
    padding: 60px 15px 40px;
  }
`;

export const MobileAppContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const MobileAppInfo = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 40%;
  margin-left: 15%;

  @media (max-width: 740px) {
    max-width: 100%;
    margin-left: 0;
  }
  @media (max-width: 600px) {
    align-items: center;
  }
`;

export const MobileAppText = styled.div`
  color: ${colors.text};
  font-size: 24px;
  max-width: 500px;
  line-height: 1.5;
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const MobileAppLinks = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 60px;

  @media (max-width: 375px) {
    gap: 10px;
  }
`;

export const MobileAppImageContainer = styled.div`
  position: absolute;
  right: 200px;
  top: -100px;

  @media (max-width: 1200px) {
    right: 5%;
  }
  @media (max-width: 900px) {
    right: 0;
  }
  @media (max-width: 740px) {
    display: none;
  }
`;

export const MobileAppImageContainer2 = styled(MobileAppImageContainer)`
  display: none;
  @media (max-width: 600px) {
    display: flex;
    position: relative;
    right: initial;
    top: initial;
    max-width: 317px;
    margin-bottom: 30px;
  }
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
`;
export const SocialMediaLink = styled.a``;
export const SocialMediaText = styled.span`
  font-size: 32px;
`;
export const AppBlockLink = styled.a``;
