const checkboxTheme = {
  sizes: {
    md: {
      label: {
        fontSize: "14px"
      }
    }
  }
};

export default checkboxTheme;
