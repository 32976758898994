import { IconProps } from "@/icons/types";

const UserIcon = ({ size = "24px", fill = "#555555" }: IconProps) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 256 256">
    <path
      fill={fill}
      d="M12 4.81784C13.0609 4.81784 14.0783 5.23927 14.8284 5.98941C15.5786 6.73956 16 7.75697 16 8.81784C16 9.87871 15.5786 10.8961 14.8284 11.6463C14.0783 12.3964 13.0609 12.8178 12 12.8178C10.9391 12.8178 9.92172 12.3964 9.17157 11.6463C8.42143 10.8961 8 9.87871 8 8.81784C8 7.75697 8.42143 6.73956 9.17157 5.98941C9.92172 5.23927 10.9391 4.81784 12 4.81784ZM12 6.81784C11.4696 6.81784 10.9609 7.02855 10.5858 7.40363C10.2107 7.7787 10 8.28741 10 8.81784C10 9.34827 10.2107 9.85698 10.5858 10.2321C10.9609 10.6071 11.4696 10.8178 12 10.8178C12.5304 10.8178 13.0391 10.6071 13.4142 10.2321C13.7893 9.85698 14 9.34827 14 8.81784C14 8.28741 13.7893 7.7787 13.4142 7.40363C13.0391 7.02855 12.5304 6.81784 12 6.81784ZM12 13.8178C14.67 13.8178 20 15.1478 20 17.8178V20.8178H4V17.8178C4 15.1478 9.33 13.8178 12 13.8178ZM12 15.7178C9.03 15.7178 5.9 17.1778 5.9 17.8178V18.9178H18.1V17.8178C18.1 17.1778 14.97 15.7178 12 15.7178Z"
    />
  </svg>
);

export default UserIcon;
