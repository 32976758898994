import React from "react";

import { IconProps } from "@/icons/types";

const MailIcon = ({ size = "20px", fill = "#000", ...props }: IconProps) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5548 5.427C21.0268 4.57 20.0798 4 18.9998 4H4.99982L4.79882 4.007C4.32166 4.03886 3.85903 4.18436 3.44953 4.43138C3.04004 4.6784 2.69555 5.01977 2.44482 5.427L11.9998 11.797L21.5548 5.427ZM22 17.0002V7.53516L12.555 13.8322L12.439 13.8982C12.3023 13.965 12.1522 13.9997 12 13.9997C11.8478 13.9997 11.6977 13.965 11.561 13.8982L11.445 13.8322L2 7.53516V17.0002L2.005 17.1762C2.04989 17.9401 2.38499 18.6579 2.94174 19.1829C3.49849 19.7078 4.23479 20.0002 5 20.0002H19L19.176 19.9952C19.9399 19.9503 20.6578 19.6152 21.1827 19.0584C21.7077 18.5017 22 17.7654 22 17.0002Z"
      fill={fill}
    />
  </svg>
);

export default MailIcon;
