import React, { ReactNode, useEffect } from "react";

import { formatDomainZone } from "@/utils";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { fetchCurrency, setCurrentCurrency } from "@/store/slices/currency";
import { selectCurrencyList } from "@/store/selectors/currency";
import { currencyAbbreviation } from "@/utils/currencyFunctions";
import { Main } from "@/components/AppLayout/styled";

import Header from "../Header";
import Footer from "../Footer";

interface IProps {
  children: ReactNode;
  host: string;
}
const AppLayout = ({ children, host }: IProps) => {
  const currencyList = useAppSelector(selectCurrencyList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCurrency());
  }, []);

  useEffect(() => {
    if (currencyList?.length) {
      const domainZone = formatDomainZone(host);
      const currency = currencyAbbreviation(domainZone);

      dispatch(setCurrentCurrency({ currencyList: currencyList, abbreviation: currency }));
    }
  }, [currencyList]);
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default AppLayout;
