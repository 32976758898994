import Belarus from "./Belarus";
import Kazakhstan from "./Kazakhstan";
import Russia from "./Russia";
import Ukraine from "./Ukraine";

export default {
  Belarus,
  Russia,
  Kazakhstan,
  Ukraine
};
