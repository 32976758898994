import { IconProps } from "@/icons/types";

const LocationIcon = ({ size = "24px", fill = "#555555", ...props }: IconProps) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      d="M11.7 11.45C10.9707 11.45 10.2712 11.1603 9.75546 10.6445C9.23973 10.1288 8.95 9.42935 8.95 8.7C8.95 7.97065 9.23973 7.27118 9.75546 6.75546C10.2712 6.23973 10.9707 5.95 11.7 5.95C12.4293 5.95 13.1288 6.23973 13.6445 6.75546C14.1603 7.27118 14.45 7.97065 14.45 8.7C14.45 9.06114 14.3789 9.41873 14.2407 9.75238C14.1025 10.086 13.8999 10.3892 13.6445 10.6445C13.3892 10.8999 13.086 11.1025 12.7524 11.2407C12.4187 11.3789 12.0611 11.45 11.7 11.45ZM11.7 1C9.65783 1 7.69931 1.81125 6.25528 3.25528C4.81125 4.69931 4 6.65783 4 8.7C4 14.475 11.7 23 11.7 23C11.7 23 19.4 14.475 19.4 8.7C19.4 6.65783 18.5888 4.69931 17.1447 3.25528C15.7007 1.81125 13.7422 1 11.7 1Z"
      fill={fill}
    />
  </svg>
);

export default LocationIcon;
