import { sendApiRequest } from "@/core/Api";

export const sendSupportMail = async (data) => {
  const res = await sendApiRequest("supportEmail", "post", { data }, true);

  return res;
};

export const sendContactMail = async (data) => {
  const res = await sendApiRequest("contactEmail", "post", { data }, true);

  return res;
};

export const sendPartnerMail = async (data) => {
  const res = await sendApiRequest("partnerEmail", "post", { data }, true);

  return res;
};
